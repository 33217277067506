import { useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, Radio } from '@mui/material';
import React from 'react';
import { FloatValues, getCurrencySign, storeAssigned } from '../../../services/Methods/normalMethods';
import _ from 'lodash';

function ShippingSection(props) {
    const { section, shippingMethods, _handleShippingChange, ShippingSection_TEXTS, selectedCheck } = props;
    const CURRENT_STORE = _.upperCase(storeAssigned()) || 'US';
    // const [selectedCheck, setSelectedCheck] = useState(null);

    // useEffect(() => {
    //     // Retrieve selected check from localStorage
    //     const storedSelectedCheck = localStorage.getItem('selectedCheck');
    //     if (storedSelectedCheck) {
    //         setSelectedCheck(JSON.parse(storedSelectedCheck));
    //     } else if (shippingMethods?.length > 0) {
    //         // Set the first available shipping method as the default selection if none is stored
    //         setSelectedCheck({ shipMethodType: shippingMethods[0]?.shipMethodType });
    //     }
    // }, [shippingMethods]);

    // useEffect(() => {
    //     // Store selectedCheck value in localStorage
    //     if (selectedCheck) {
    //         localStorage.setItem('selectedCheck', JSON.stringify(selectedCheck));
    //     }
    // }, [selectedCheck]);

    const handleShippingChange = (data, section) => {
        // setSelectedCheck({ shipMethodType: data?.shipMethodType });
        _handleShippingChange(data, section);
    };

    return (
        <div className='shipping_option_div'>
            <h3 style={{ textTransform: "capitalize" }}>{section} {ShippingSection_TEXTS?.shipMethod_text}</h3>
            <div className='shipping_methodOptions mb-[30px]'>
                {(shippingMethods?.length > 0) ? (
                    shippingMethods?.map((data) => {
                        const FormattedValue = Math.floor(parseFloat(data?.amount) * 100) / 100;
                        const isChecked = selectedCheck?.shipMethodType === data?.shipMethodType;
                        console.log("data>>>>>>>>>>>>>>>>>>>>>", data);
                        return (
                            data?.shipMethodType !== 100 && <Box className="shiping_methods" key={data?.shipMethodType}>
                                <FormControl className="radio_option">
                                    <FormControlLabel
                                        value={data?.shipMethodType}
                                        control={
                                            <Radio
                                                checked={isChecked}
                                                onChange={() => handleShippingChange(data, section)}
                                                name="radio-button-demo"
                                                inputProps={{ "aria-label": "A" }}
                                            />
                                        }
                                        label={
                                            <p className='my-0'>{`${data?.shipMethodDescription}: ${getCurrencySign(CURRENT_STORE)}
                                            ${FormattedValue.toFixed(2)}
                                            `}</p>
                                        }
                                    />
                                </FormControl>
                            </Box>
                        );
                    })
                ) : (
                    <p className='error'>{ShippingSection_TEXTS?.noShipMethod_text}</p>
                )}
            </div>
        </div>
    );
}

export default ShippingSection;
