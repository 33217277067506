import React from 'react';
import useAutoshipEditHook from './components/AutohsipEdit.hook';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import AutoshipAddressEdit from './components/AutoshipAddressEdit';
import AutoshipProductDetail from './components/AutoshipProducts';
import AutoshipMainPageStyle from './styles/AutoshipMainPage.style';
import { ButtonCssClass, ErrorDisplay, SuccessDisplay, _productPrice } from '../../services/Methods/normalMethods';
import AutoshipProductsAddStyle from './styles/AutoshipProductsAdd.style';
import _ from 'lodash';
import withRouter from '../../services/HOC/withRouterHOC';
import PaymentAndSavedCard from './components/PaymentAndSavedCard';
import { DialogActions } from '@mui/material';
import DialogComponent from '../../Inputs/DailogComponent';
import { AddressSuggestionDialog } from '../../services/Methods/commonPayload';
import ButtonComponent from '../../Inputs/ButtonComponent';
import { setErrorAddressData } from '../../services/Redux/Reducer/CheckoutSlice';
import LoaderComponent from '../../Inputs/LoaderComponent';
import withProductHOC from '../../services/HOC/withProductHOC';
import ProductDisplaySection from '../../Common/Components/AllFormsCommons/ProductDisplaySection';
import AddressOptions from '../../Common/Components/AllFormsCommons/AddressOptions';

const AutoshipEditPage = (props) => {
    const [searchParams] = useSearchParams();
    const { isUserLogin, Dates, countryCodes, userData, addToCartProducts, setAddToCartProduct } = useOutletContext();

    const {
        iframeLoader,   error,
        autoshiporder,
        _handleUpdateAutoship, updateSuccess,
        autoshipOrderDetail, _handleQuantiyIncDec,
        _handleProductAdd, _handleProductDelete,
        addressEditOpen, setAddressEditOpen,
        openProductAdd, setOpenProductAdd,
        _handleAddressEditSubmit,
        countryState, setCountryState,
        frequencyTYpe, setFrequencyType,
        addressDetails, setAddressDetails,
        commonLoader,
        shippingMethods,
        selectedAutoshipCheck,
        _handleOnSelectedAutoshipChange,
        calculatedAutoshipData,
        dispatch,
        errorAddressData, normalAddressError,
        useDefaultAddressOrNot, setUseDefaultAddressOrNot,
        _callAutoshipCalculateApi,
        savedThisCard, setSavedThisCard,
        savedCards, _handleCardChange,
        selectedPayment, setSelectedPayment, _addNewCard,
        deletePopUp, _openCloseAskPopup,
        selectedOptionForPayment,
        paymentOptions, paymentFormHtml, sameAsBillingChecked, _paymentOptionSetBilling, setSameAsBillingChecked, paymentOption_ID, cashPayNote,

        handleChangeFunction, _setUseDefaultAddressFunction,
        selectedDate, setSelectedDate
    } = useAutoshipEditHook({ isUserLogin, Dates, autoshipId: searchParams.get('orderid') });

    return (
        <AutoshipMainPageStyle>
            <div className='main_autoship_edit container px-[15px] m-auto'>
                {commonLoader && <LoaderComponent />}
                <div className='autohsip_banner'>
                    <h2>Edit Autoship</h2>
                </div>
                {updateSuccess && <div className='errorMsg'><SuccessDisplay message={updateSuccess} /></div>}
                <div className='contents'>
                    <AutoshipProductDetail {...{
                        autoshiporder,
                        selectedDate, setSelectedDate,
                        setOpenProductAdd, _openCloseAskPopup, _handleQuantiyIncDec,
                        shippingMethods, frequencyTYpe, _handleOnSelectedAutoshipChange,
                        selectedAutoshipCheck, setFrequencyType, calculatedAutoshipData,
                        ...props
                    }}>
                        <AddressOptions {...{
                            section: 'autoship_edit',
                            _setUseDefaultAddressFunction,
                            allUserData: userData, handleChangeFunction, userFormData: addressDetails, setUserFormData: setAddressDetails,
                            countryCodes, normalAddressError, countryState, setCountryState,
                        }} />
                    </AutoshipProductDetail>

                    <PaymentAndSavedCard  {...{
                        sameAsBillingChecked, _paymentOptionSetBilling, setSameAsBillingChecked, addressDetails,
                        paymentFormHtml, paymentOptions, selectedOptionForPayment,iframeLoader,paymentOption_ID, cashPayNote,
                        _addNewCard, isUserLogin,
                        savedCards, _handleCardChange, selectedPayment, setSelectedPayment,
                        savedThisCard, setSavedThisCard,
                        ...props,
                    }} />
                </div>
                <div className='update_autoship'>
                    <ButtonComponent classes="update_button" onClick={() => _handleUpdateAutoship()}>Update Autoship</ButtonComponent>
                </div>
                <AddressSuggestionDialog {...{
                    errorAddressData, useDefaultAddressOrNot, closeFunction: () => dispatch(setErrorAddressData({ objectData: null, section: "object" })),
                    setUseDefaultAddressOrNot: (data) => {
                        if (typeof data === 'boolean') {
                            setUseDefaultAddressOrNot(data);
                            _callAutoshipCalculateApi(autoshipOrderDetail, selectedAutoshipCheck, addressDetails, useDefaultAddressOrNot);

                        } else {
                            setUseDefaultAddressOrNot(data);
                            setCountryState({ ...countryState, country: data?.country, state: data?.state_region });
                            const ADDRESS_DATA = {
                                ...addressDetails,
                                shipping_street: data?.address_1,
                                shipping_street2: data?.address_2,
                                shipping_city: data?.city,
                                shipping_country: data?.country,
                                shipping_state: data?.state_region,
                                shipping_zipcode: data?.postal_code
                            }
                            setAddressDetails(ADDRESS_DATA)
                            _callAutoshipCalculateApi(autoshipOrderDetail, selectedAutoshipCheck, ADDRESS_DATA, data);

                        }
                    }, userData: addressDetails
                }} />
                <div className='dailog'>
                    <DialogComponent opend={openProductAdd} handleClose={() => setOpenProductAdd(false)} title="Add Product" classFor="addProduts">
                        <AutoshipProductsAddStyle>
                            <ProductDisplaySection onetime={false} {...{ _handleProductAdd, addToCartProducts, setAddToCartProduct, setOpenMiniCart: setOpenProductAdd, section: 'normal', productSection: 'autoshipEdit' }} />
                        </AutoshipProductsAddStyle>
                    </DialogComponent>
                </div>
            </div>
            <DialogComponent opend={deletePopUp?.open} handleClose={() => _openCloseAskPopup(null, 'close')} title="Alert" classFor="isDistributer deleteAsking_popup">
                <div className='py-[20px]'>
                    <p style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                    }} className='text-semibold text-lg text-black'> Are you sure want to delete this ?</p>
                </div>
                <DialogActions className='action_button justify-center' >
                    <button className={ButtonCssClass} onClick={() => _handleProductDelete(deletePopUp?.id)}> Yes</button>
                    <button className={ButtonCssClass} onClick={() => _openCloseAskPopup(null, 'close')}>  No </button>
                </DialogActions>
            </DialogComponent>
        </AutoshipMainPageStyle>
    )
}

export default withRouter(AutoshipEditPage);