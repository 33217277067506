import { Clear } from '@mui/icons-material';
import classNames from 'classnames';
import _ from 'lodash';
import { Box, FormControl, FormControlLabel, IconButton, Radio } from '@mui/material';
import React from 'react'
import styled from 'styled-components';

const SavedCardStyle = styled.div`

.savedcard_methods {
    border: 1px solid #ddd;
    width: 100%;
    max-width: 500px;
    padding: 10px;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
        width: 100%;
        max-width: 90px;
        padding: 7px;
        border-radius: 0;
        margin-left: 10px;
    }
}   
.paymentOptions {
     display: flex;
     gap: 40px;
     margin-bottom: 30px;

     @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
     }
    .box_container {
            cursor: pointer;
            padding: 20px 10px;
            width:100%;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #000;
            box-shadow: 0 0 7px 0px #0071BA;
            border-radius: 10px;
            &.selected {
                background: #0071BA;
                color: #fff;
            }
    }
}
.credit_card_section {
     select {
            width: calc(50% - 17px/2);
            background: transparent;
            font-size: 18px;
            line-height: 1.5;
            border: 1px solid rgb(196, 196, 196);
            border-radius: 5px;
            color: rgba(85, 85, 85, 0.4);
            height: 50px;
            @media only screen and (max-width:1199px) {
                width: 100%;
            }
            @media only screen and (max-width:568px) {
                margin-top: 20px;
                width: 100%;
            }
        }
    }
`;

const OptionsSelection = (props) => {
    const { title, optionData, checked, _onChangeFunction, section } = props;
    return (
        <>
            {/* <h3 style={{ textTransform: "capitalize" }}>{title}</h3> */}
            {(optionData?.length > 0) ? (
                optionData?.map((data, index) => {
                    return (
                        <Box className="savedcard_methods" key={index + 1}>
                            <FormControl className="radio_option">
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Radio
                                            checked={(section === "savedCard") ? (checked === data?.customerAccountId) : (checked === data?.paymentOptionId)}
                                            onChange={() => _onChangeFunction(data)}
                                            name="radio-button-demo"
                                            inputProps={{ "aria-label": "A" }}
                                        />
                                    }
                                    label={
                                        <p style={{ margin: 0 }}>{(section === "savedCard") ? data?.creditCardDisplay : data?.description}</p>
                                    }
                                />
                            </FormControl>
                            {/* {(checked === data?.customerAccountId) &&
                                <div className='cvv_section'>
                                    <Clear style={{ cursor: "pointer" }} onClick={() => _onChangeFunction(null)} />
                                </div>
                            } */}
                        </Box>
                    );
                })
            ) : (
                <p>No Saved Payment Method</p>
            )}
        </>
    )
}


const SavedCards = (props) => {
    const {
        paymentOptions, selectedPayment, savedCards, _handleCardChange, setSelectedPayment, SavedCards_TEXTS,
        setSameAsBillingChecked
    } = props;

    const { cardValue, selectedPaymentDropdown, selectedOptionForPayment } = selectedPayment;

    function _handlePaymentSectionSelection(num = 1) {
        if (setSameAsBillingChecked) {
            setSameAsBillingChecked(false);
        }
        setSelectedPayment((prv) => ({ ...prv, selectedOptionForPayment: num }))
    }
    console.log("selectedOptionForPayment", selectedOptionForPayment, "selectedPaymentDropdown", selectedPaymentDropdown, "cardValue", cardValue);

    return (
        <SavedCardStyle className='savedcard_option_div'>
            {(savedCards?.length > 0) &&
                <div className='paymentOptions'>
                    <div onClick={() => {
                        _handlePaymentSectionSelection(1);
                        _handleCardChange(0, 'paymentOption0')
                        setSelectedPayment((prv) => ({ ...prv, selectedPaymentDropdown: 0 }));
                    }} className={classNames('box_container', { 'selected': (selectedOptionForPayment === 1) })}>{SavedCards_TEXTS?.savedPayment_text}</div>

                    <div onClick={() => {
                        _handlePaymentSectionSelection(2)
                        _handleCardChange(paymentOptions[0]?.paymentOptionId, 'paymentOption')
                        if (paymentOptions?.length > 0) {
                            setSelectedPayment((prv) => ({ ...prv, selectedPaymentDropdown: paymentOptions[0]?.paymentOptionId }));
                        }
                    }} className={classNames('box_container', { 'selected': (selectedOptionForPayment === 2) })}>{SavedCards_TEXTS?.addPayment_text}</div>
                </div>}
            {
                (selectedOptionForPayment === 1) &&
                <div className='saved_card_section'>
                    <OptionsSelection
                        title=""
                        section="savedCard"
                        optionData={savedCards}
                        checked={cardValue?.customerAccountId || cardValue?.nextAmount?.customerAccountId}
                        _onChangeFunction={(data) => _handleCardChange(data, 'savedCard')}
                    />
                </div>
            }
            {
                (selectedOptionForPayment === 2 && paymentOptions?.length > 1) &&
                <div className='credit_card_section'>
                    <select value={selectedPaymentDropdown || ""} onChange={(e) => _handleCardChange(+e.target.value, 'paymentOption')}>
                        {_.map(paymentOptions, (row) => (<option key={row?.paymentOptionId} value={row?.paymentOptionId}>{row?.description}</option>))}
                    </select>

                </div>
            }
        </SavedCardStyle >

    )
}

export default SavedCards;