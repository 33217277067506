const ROUTING_TEXT = {
    landing_page_routingName: '',
    about_routingName: 'about',
    explore_business_routingName: 'explore_business',
    explore_health_routingName: 'explore_health',
    signup_routingName: 'signup',
    privacy_routingName: 'privacy',
    refund_routingName: 'refund',
    shipping_info_routingName: "shipping_info",
    login_routingName: 'login',
    forgot_password_routingName: 'forgot_password',
    reset_password_routingName: 'reset_password',
    home_routingName: 'home',
    terms_routingName: 'terms',
    income_routingName: 'income',
    checkout_routingName: 'checkout',
    userinfo_routingName: 'userinfo',
    retail_checkout_routingName: "retail_checkout",
    retailSignup_routingName: 'retail_signup',
    customerSignup_routingName: 'customer_signup',
    preferredSignup_routingName: 'preferred_customer',
    IBOSignup_routingName: 'business_owner',
    Shop_routingName: 'shop',
    viewCart_routingName: 'cart',
    thankyou_routingName: 'thankyou',
    autoship_routingName: 'autoship_edit',
    ktm_routingName: 'KTMist',
    incentive_routingName: 'you-share-they-share' , 
    powerline_routingname : 'powerline'
};

export const SIGNUP_TEXT = {
    readyTo_checkUp: "Ready to Check Out ?",
    selectHow_ToJoin: "Select how you'd like to join:",
    retialCustomer_text: "Retail Customer",
    preferredCustomer_text: 'Preferred Customer',
    independentOwner_text: 'Independent Business Owner',
    retailDescription: "Shop Provizion at retail price with no sign-up fee.",
    preferredDescription: "Sign-up for free + be willing to refer others + enjoy 10% off retail + bulk ordering discounts.",
    independentDescription: "Sign-up for free + start a business to earn money + enjoy 10% off retail + bulk ordering discounts.",

    wholesale_text: "Wholesale",
    createAccount: "Create Account",

    Register_Steps: {
        retailer_steps: ['Product', 'Start', 'Validate', 'Checkout'],
        preffered_steps: ['Product', 'Start', 'Validate', 'Checkout'],
        IBO_steps: ['Product', 'Start', 'Validate', 'Checkout']
    }

}

export const LOGIN_TEXT = {
    sign_up: 'Sign Up',
    sign_in: 'Sign In',
    email_text: "Email Address",
    password: "Password",
    forgetPassword_text: "Forgot password"
}


export default ROUTING_TEXT;