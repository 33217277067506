import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import localStorageCall from '../../services/Methods/localstorage.hook';
import { FloatValues, SignupRoutes, storeAssigned, _logoutFunction, getCurrencySign } from '../../services/Methods/normalMethods';
import { useDispatch } from 'react-redux';
import { MyAccoutnAPI } from '../../services/Redux/Reducer/LoginSlice';
import CountryFlagDropdown from './CountryFlagDropdown';
import ReactCountryFlag from "react-country-flag";
import SupportContactUs from './SupportContactUs';
import { useRef } from 'react';
import { useEffect } from 'react';
import useOutsideAlerter from '../../Common/Components/useOutsideAlerter';

const Header = (props) => {
    const {
        COMMON_TEXTS, ROUTING_TEXT, location, subTotal, navigate, dispatch,
        isUserLogin, countyObjects, userData,
        showCountries, setShowCountries, selectedStore, setSelectedStore
    } = props;

    const wrapperRef = useRef(null);

    // const subTotal = useMemo(() => {
    //     if (addToCartProducts?.normalProducts?.length > 0) {
    //         return ({
    //             productCount: _.map(addToCartProducts?.normalProducts, 'quantity').reduce((prv, next) => prv + next),
    //             productSubTotal: _.map(addToCartProducts?.normalProducts, (row) => ((!!(_.find(autoshipOrders, { isActive: true })) || _autoShipCheck()) ? row['normal_autoship'] : row['normal_' + row?.frequency]) * +row?.quantity).reduce((prv, next) => prv + next)
    //         })
    //     }
    // }, [addToCartProducts?.normalProducts]);

    const [showMenu, setShowMenu] = useState(false);
    const [showContact, setShowContact] = useState(false);

    const HEADER_LINKS = [
        { link: `/${storeAssigned()}/${ROUTING_TEXT?.Shop_routingName}`, text: COMMON_TEXTS?.shop_text },
        { link: `/${storeAssigned()}/${ROUTING_TEXT?.explore_health_routingName}`, text: COMMON_TEXTS?.explore_health },
        { link: `/${storeAssigned()}/${ROUTING_TEXT?.explore_business_routingName}`, text: COMMON_TEXTS?.explore_text + ' ' + COMMON_TEXTS?.business_text },
        { link: `/${storeAssigned()}/${ROUTING_TEXT?.signup_routingName}`, text: COMMON_TEXTS?.create_account },
        { link: `/${storeAssigned()}/${ROUTING_TEXT?.about_routingName}`, text: COMMON_TEXTS?.about },
    ];

    function handleBackOffice() {
        if (isUserLogin) {
            dispatch(MyAccoutnAPI())
        }
        else {
            navigate(`/${storeAssigned()}/${ROUTING_TEXT?.login_routingName}`)
        }
    };

    const boxRef = useRef(null);
    // boxOutsideClick will be true on outside click
    const boxOutsideClick = OutsideClick(boxRef);
    function OutsideClick(ref, showMenu) {

        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {

                    setShowMenu(false)
                } else {

                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
        return showMenu;
    }
    const CURRENT_STORE = _.upperCase(storeAssigned()) || 'US';

    useOutsideAlerter(wrapperRef, () => {
        setShowCountries(false);
    });

    return (
        <React.Fragment>
            {(location.pathname !== ROUTING_TEXT?.signup_routingName) &&
                <>
                    <div className="top-header-wrap bg-[#373f50] py-[11px]">
                        <div className="container max-w-[1260px] mx-auto px-[15px]">
                            <div className="top-header flex flex-wrap items-center justify-between">
                                <div className="support-header flex flex-wrap items-center" >
                                    <span className="text-[#0071BA] mr-[10px] "> <i className="fa-regular fa-envelope"></i> </span>
                                    <a className='text-white cursor-pointer opacity-60 text-[16px] leading-[24px] font-normal hover:opacity-100 transition-all ease-in-out duration-300' onClick={() => {
                                        setShowContact(true);
                                    }}>Support</a>
                                </div>
                                <div className='flex items-center'>
                                    <div>
                                        <p className='text-white opacity-60 text-[14px] leading-[21px] font-normal'>
                                            {!(isUserLogin) ?
                                                `${localStorageCall().getItem('refferal_link')?.name ? COMMON_TEXTS?.reffer_by + ': ' + localStorageCall().getItem('refferal_link')?.name : ''}`
                                                :
                                                COMMON_TEXTS?.welcomeText + ', ' + (userData?.fullName || '')
                                            }
                                        </p>
                                    </div>
                                    <div className='relative' ref={wrapperRef}>
                                        <button className="topbar-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false" onClick={() => {
                                            setShowCountries(!showCountries);
                                        }}>
                                            <ReactCountryFlag countryCode={selectedStore?.defaultCode || 'US'} style={{
                                                fontSize: '1em', marginRight: '2px', lineHeight: '2em', marginLeft: '1rem'
                                            }} />
                                        </button>
                                        <CountryFlagDropdown {...{ selectedStore, setSelectedStore, countyObjects, showCountries, setShowCountries }} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className=" print_logo container max-w-[1260px] mx-auto px-[15px] relative z-20" ref={boxRef}>
                        <nav
                            className="relative flex w-full flex-nowrap items-center justify-between py-[16px] lg:flex-wrap lg:justify-start"
                            data-te-navbar-ref>
                            <div className="flex w-full flex-wrap items-center justify-between">
                                <Link to={`/${storeAssigned()}`} reloadDocument className="max-w-[142px] h-[51px] block mr-[24px] my-[10px]">
                                    <img className='block h-full w-full object-contain' src={logo} alt="logo" />
                                </Link>
                                <button id="nav-toggle"
                                    className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden ml-auto"
                                    type="button"
                                    data-te-collapse-init
                                    data-te-target="#navbarSupportedContent2"
                                    aria-controls="navbarSupportedContent2"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={() => setShowMenu(!showMenu)}>
                                    <span className="[&>svg]:w-7">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="h-7 w-7">
                                            <path
                                                fillRule="evenodd"
                                                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                                                clipRule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                                <div
                                    className={`!visible mt-2 hidden flex-grow basis-[100%] items-center lg:mt-0 lg:!flex lg:basis-auto ${showMenu ? "open-menu" : ""} [&.open-menu]:block [&.open-menu]:absolute [&.open-menu]:top-full [&.open-menu]:-left-[15px] [&.open-menu]:w-[100vw] [&.open-menu]:h-auto [&.open-menu]:m-0 [&.open-menu]:bg-white [&.open-menu]:shadow-[0px_0px_10px_rgba(0,0,0,0.1)] group`}
                                    id="navbarSupportedContent2"
                                    data-te-collapse-item >
                                    <ul className="list-style-none flex pl-0 lg:flex-row:m-0 group-[.open-menu]:flex-col group-[.open-menu]:p-[15px]" data-te-navbar-nav-ref>
                                        {_.map(HEADER_LINKS, (row, index) => (
                                            <li
                                                key={index + 1}
                                                className={classNames('', { "mr-[36px] group-[.open-menu]:mr-0 group-[.open-menu]:mb-[5px]": (HEADER_LINKS?.length - 0 !== index) })}
                                                data-te-nav-item-ref>
                                                <Link
                                                    reloadDocument
                                                    className={`text-[#4b566b] text-[16px] leading-[24px] inline-block hover:text-[#0071BA] transition-all ease-in-out duration-300`}
                                                    to={row?.link}>
                                                    {row?.text}
                                                </Link>
                                            </li>
                                        ))}
                                        <li onClick={() => handleBackOffice()} className="mr-[36px] group-[.open-menu]:mr-0 group-[.open-menu]:mb-[5px]" data-te-nav-item-ref>
                                            <Link
                                                className={`text-[#4b566b] text-[16px] leading-[24px] mx-0[12px] inline-block hover:text-[#0071BA] transition-all ease-in-out duration-300`}
                                                to='#'>
                                                Back Office
                                            </Link>
                                            {/* <span>Back Office</span> */}
                                        </li>
                                    </ul>
                                </div>
                                <ul className='flex items-center'>
                                    <li className='lg:mr-[24px]'>
                                        {!(isUserLogin)
                                            ?
                                            <Link to={`/${storeAssigned()}/login`} className='flex items-center text-[0px] lg:text-[14px] leading-[21px] text-[#4b566b]'>
                                                <span className='block text-[20px] leading-4 h-[46px] w-[46px] rounded-full flex items-center justify-center'>
                                                    <i className="fa-regular fa-user"></i>
                                                </span>
                                                {COMMON_TEXTS?.sign_in}
                                            </Link >
                                            :
                                            <Link onClick={() => _logoutFunction('redirect', userData)} className='flex items-center text-[0px] lg:text-[14px] leading-[21px] text-[#4b566b]'>
                                                <span className='block text-[20px] leading-4 h-[46px] w-[46px] rounded-full flex items-center justify-center'>
                                                    <i className="fa fa-sign-out"></i>
                                                </span>
                                                {COMMON_TEXTS?.logout}
                                            </Link>
                                        }
                                    </li>
                                    <li>
                                        <Link to={SignupRoutes(location) ? `/${storeAssigned()}/${ROUTING_TEXT?.viewCart_routingName}` : '#'} className='flex items-center text-[0px] lg:text-[14px] leading-[21px] text-[#4b566b]'>
                                            <span className='block text-[20px] leading-4 h-[46px] w-[46px] rounded-full flex items-center justify-center lg:mr-[14px] bg-[#f3f5f9]'>
                                                <i className="fa-solid fa-cart-shopping"></i>
                                            </span>
                                            <span>
                                                <small className='block text-[#7d879c] text-[0px] lg:text-[11px] leading-[16px]'>{COMMON_TEXTS?.my_cart}</small>
                                                {getCurrencySign(CURRENT_STORE)}{FloatValues(subTotal?.productSubTotal || 0.00)}
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div >
                    <SupportContactUs {...{ showContact, setShowContact }} />
                </>
            }
        </React.Fragment >
    );
};
export default Header;