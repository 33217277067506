import _ from "lodash";
import ROUTING_TEXT from "../../TextConfigs/RoutingAndSmallContent.text";
import localStorageCall from "./localstorage.hook";
import * as Sentry from "@sentry/react";

/****** current store of product ******/
export function storeAssigned() {
    const CURRENT_STORE = localStorageCall().getSingleItem('store') || 'us';
    return CURRENT_STORE;
}

export const ButtonCssClass = "relative f-q-heavy text-xs sm:text-[8px] md:text-[10px] lg:text-[11px] xl:text-[18px] w-[140px] h-[40px] sm:w-[85px] sm:h-[28px] md:w-[100px] md:h-[31px] lg:w-[135px] lg:h-[38px] xl:w-[170px] xl:h-[50px]  pt-0.5 z-10 inline-block rounded-full border-2 bg-button-color border-button-color text-white hover:bg-white hover:text-button-color transition-colors duration-200";
export const REFFERAL_VALUES = localStorageCall().getItem('refferal_link');  /*** get Refferal value from cookiee ***/
export const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr') ? localStorageCall().getItem('customerTypeDescr') : { priceType: 1, customerType: 'Retail Customer (RC)' };
export const MIDDLE_CREDENTIAL = { 'Content-Type': 'multipart/form-data' };
export const CacheHeader = { 'Cache-control': 'no-cache' };

/******* get Login user token ********/
export function _getToken() {
    try {
        const token = localStorageCall().getSingleItem('Token') ? localStorageCall().getSingleItem('Token') : false;
        return token;
    } catch (e) {
        return null;
    }
};

export function _getEnrollerId() {
    try {
        const enrollerId = localStorage.getItem('enrollerId') ? JSON.parse(localStorage.getItem('enrollerId')) : false;
        return ({ enrollerId: enrollerId?.enrollerId, status: !_.isEmpty(enrollerId), id: enrollerId?.customerId, name: enrollerId?.fullName });
    } catch (e) {
        return ({ enrollerId: null, status: false, id: null, name: '' });
    }
}
export function _getSSOKeyData() {
    try {
        const SSOKeyData = localStorage.getItem('SSO_KEY_DATA') ? JSON.parse(localStorage.getItem('SSO_KEY_DATA')) : false;
        return ({ enrollerId: SSOKeyData?.id, status: !_.isEmpty(SSOKeyData), name: SSOKeyData?.name });
    } catch (e) {
        return ({ enrollerId: null, status: false, name: '' });
    }
}

export function SignupRoutes(location) {
    return !_.includes([
        `/${storeAssigned()}/${ROUTING_TEXT?.retailSignup_routingName}`,
        `/${storeAssigned()}/${ROUTING_TEXT?.preferredSignup_routingName}`,
        `/${storeAssigned()}/${ROUTING_TEXT?.IBOSignup_routingName}`
    ], location?.pathname)
}

export function _setTimeOut(callback, seconds = 3000) {   /****** setTime out function ******/
    setTimeout(() => callback(), seconds);
}

export const FloatValues = (value, count = 2) => {
    if (!_.includes(String(value), '.')) {
        return parseFloat(value)?.toFixed(count);
    } else {
        const roundedValue = Math.floor(parseFloat(value) * 100) / 100;
        return _.round(value)?.toFixed(2);
    }
};                  /***** convert price into float value *****/


export const ErrorDisplay = ({ message }) => (<span className='text-base text-red-500' style={{ color: "error" }}>{message}</span>);   /**** display error message ****/

export const SuccessDisplay = ({ message }) => (<span className='text_sucess' style={{ color: "green", display: "flex", textAlign: "center" }}>{message}</span>);  /**** display success message ****/

/****** logout user function ******/
export function _logoutFunction(status = "redirect", userData) {
    // console.log('run');
    if (status === 'redirect') {
        const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');
        const userdata = JSON.stringify({ "id": userData?.customerId, "name": userData?.fullName, "webalies": userData?.webAlias });
        if (CUSTOMER_TYPE?.role !== 'retail' && userData?.webAlias) {
            localStorageCall().setItem('refferal_link', userdata);
        }
    }
    localStorageCall().removeItem('Token');
    localStorageCall().removeItem('customerTypeDescr');
    const PRODUCTS = localStorageCall().getItem('normalProducts') || [];

    let PRODUCTS_DATA = _.map(PRODUCTS, (row) => {
        let UPDATED_PRODUCTS = { ...row, normal_autoship: row[`retail_autoship`], normal_oneTime: row[`retail_oneTime`] };
        return UPDATED_PRODUCTS;
    });
    localStorageCall().setItem(`normalProducts`, JSON.stringify(PRODUCTS_DATA));
    if (status === "redirect") {
        setTimeout(() => window.location.assign(`/${storeAssigned()}/login`), 1000);
    }
    Sentry.setUser(null);
}

export function handleRoute(id, section) {                                /****** Routing with store ******/
    window.location.assign(`/${storeAssigned()}/${section}#${id}`)
}
export function checkSection() {
    const data = localStorageCall().getItem('customerTypeDescr');
    return data?.role;
}

export function _selectedEnrollmentProduct() {
    return localStorageCall().getItem('selectedEnrollmentPack') ? localStorageCall().getItem('selectedEnrollmentPack') : [];
}

export function _selectedIBOProduct() {
    return localStorageCall().getItem('selectedIBOProducts') || [];
}

export const MiniLoader = () => <div className="h-8 w-8 animate-spin rounded-full mx-auto border-4 border-solid border-[#303030] border-t-transparent"></div>;

export function _convertStringKeyValueInObject(errorMessage) {
    const objectData = {};
    _.forEach(_.compact(errorMessage?.split("\r\n")), (value) => {
        const INNER_SPLIT = _.split(value, ":");
        _.assign(objectData, { [_.lowerCase(INNER_SPLIT[0])?.replaceAll(/\s/g, "_")]: INNER_SPLIT[1]?.trim() });
    });
    return objectData;
}

//formdata_converter
export function _getFormDataConvert(valuesObj) {
    const data = _.entries(valuesObj);
    const formdata = new FormData();
    _.forEach(data, ([key, value]) => { formdata.append(key, value); })
    return formdata;
}

// form 

// export function _getPrice(row, id) {
//     return _.find(row?.itemPricing, (row) => (row?.countryCode === `US` && row?.priceType === id))?.itemPrice
// }


//int number format

export function _getPrice(row, id, countryCode = 'US') {
    // const CURRENT_STORE = _.upperCase(storeAssigned()) || countryCode;
    const CURRENT_STORE = _.upperCase(storeAssigned()) || countryCode;
    const item = _.find(row?.itemPricing, (item) => (item?.countryCode === CURRENT_STORE && item?.priceType === id));
    if (item) {
        // return formatPrice(item.itemPrice, CURRENT_STORE);
        return +item.itemPrice;
    }
    return undefined;
}

function formatPrice(price, countryCode = 'US') {
    const formattedPrice = new Intl.NumberFormat(countryCode, {
        style: 'currency',
        currency: getCurrencyCode(countryCode),
        minimumFractionDigits: 0,
    }).format(price);

    const priceWithoutCurrency = formattedPrice.replace(/[^0-9.]/g, '');
    return priceWithoutCurrency;
}

export function getCurrencyCode(countryCode) {
    const currencyCodeMapping = {
        'US': 'USD',
        'CA': 'USD',
        'GB': 'USD',
        'AU': 'USD',
        'NZ': 'USD',
        'MX': 'USD',
        'NG': 'USD',
        'PH': 'USD',
        'CD': 'USD',
        'CM': 'USD',
        'KE': 'USD',
        'MU': 'USD',
    };
    return currencyCodeMapping[countryCode];
}


// to change the currency sign accroding to the country//

export const getCurrencySign = () => {
    const CURRENT_STORE = _.upperCase(storeAssigned()) || countryCode;
    switch (CURRENT_STORE) {
        case 'US':
            return '$';
        case 'CA':
            return '$';
        case 'GB':
            return '$';
        case 'AU':
            return '$';
        case 'NZ':
            return '$';
        case 'MX':
            return '$';
        case 'NG':
            return '$';
        case 'PH':
            return '$';
        case 'CD':
            return '$';
        case 'CM':
            return '$';
        case 'KE':
            return '$';
        case 'MU':
            return '$';
        default:
            return '$';
    }
};

// ******************* points
export function _pointsPrice(priceList, selectedPrice, isEligibleForAutoOrder) {
    const CUSTOMER_TYP = localStorageCall().getItem('customerTypeDescr');
    let bb = (CUSTOMER_TYP?.priceType && CUSTOMER_TYP?.priceType !== 1 && isEligibleForAutoOrder) ? CUSTOMER_TYP?.priceType : (selectedPrice === "autoship" && isEligibleForAutoOrder) ? 5 : 1;
    const PRICE = _.find(priceList, ({ countryCode, priceType }) => {
        return (countryCode === _.upperCase(storeAssigned()) && priceType === bb)
    })?.points || 0;
    return PRICE;
}

/****** Product price based on customer  profile type ******/
export function _productPrice(priceList, selectedPrice, isEligibleForAutoOrder) {
    const CUSTOMER_TYP = localStorageCall().getItem('customerTypeDescr') || {};
    // console.log(CUSTOMER_TYP?.priceType, isEligibleForAutoOrder);
    let bb = (CUSTOMER_TYP?.priceType && CUSTOMER_TYP?.priceType !== 1 && isEligibleForAutoOrder) ? CUSTOMER_TYP?.priceType : (selectedPrice === "autoship" && isEligibleForAutoOrder) ? 5 : 1;
    const PRICE = _.find(priceList, ({ countryCode, priceType }) => {
        // console.log(priceList, priceType, countryCode, 'PRICE >> >> > >> > > > > >> > ')
        return (countryCode === _.upperCase('us') && priceType === bb)
    })?.itemPrice || 0;
    return FloatValues(PRICE);
}

export function _getStoreFrontId(section) {
    // console.log("section==============", section);
    const AllPRODUCTSCOUNTRYID = {
        1005: ['us'],
        1011: ['ca'],
        1017: ['au'],
        1023: ['nz'],
        1029: ['mx'],
        1035: ['ph'],
        1041: ['ng'],
        1047: ['cd'],
        1053: ['cm'],
        1059: ['ke'],
        1065: ['mu'],
        1071: ['gb'],
    };

    const getId = (countryCode, idMap) => {
        for (const [id, countries] of Object.entries(idMap)) {
            if (countries.includes(countryCode)) {
                return id;
            }
        }
        return 1005;
    }
    let id = 1;

    switch (section) {
        case 'All Products':
            id = getId(storeAssigned(), AllPRODUCTSCOUNTRYID);
            break;
        default:
            id = 1;
            break;
    }

    return id;
}