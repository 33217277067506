import React from 'react'
import { Formik, Form } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import RegisterForms from './RegisterForms';
import OneTimePass from './OtpSection';
import ProductDisplaySection from './ProductDisplaySection';
import CheckoutSection from './CheckoutSection';
import { signUpValidationSchema } from '../../../services/Methods/validationSchema';
import { CreateObject } from '../../../services/Methods/checkoutPayloads';
import _ from 'lodash';

const MainStepperSignUp = (props) => {
    const {
        // make sure you have to pass section && products
        section, cartProducts,

        otp, countryCodes, FormId, activeStep, setActiveStep, formData, emailExistError, setOpenMiniCart,
        countryState, setCountryState, addToCartProducts, setAddToCartProduct, webAliesError, setWebAliesErros,
        handleChangeValidate, _handleSubmit, _handleOtpSubmit, isUserLogin, isWebAlias, _validateRefferalFunction,

    } = props

    const AutoshipTrue = _.find(addToCartProducts?.[`${section}Products`], { frequency: 'autoship' })
    const PRODUCTS_ON_CART = _.map(addToCartProducts?.[`${section}Products`], (r) => CreateObject(r, AutoshipTrue));
    const AUTOSHIP_PRODUCTS_ON_CART = _.map(_.filter(addToCartProducts?.[`${section}Products`], ({ frequency, ...row }) => (frequency === 'autoship' && !row.member)), (r) => CreateObject(r, AutoshipTrue));

    const getStepContent = (step, fieldValue) => {
        switch (step) {
            case 0: return <ProductDisplaySection {...{
                cartProducts, addToCartProducts, setAddToCartProduct, setOpenMiniCart, activeStep, setActiveStep, section: section
            }} />;
            case 1: return <RegisterForms {...{
                fieldValue, emailExistError, countryState, setCountryState, countryCodes, webAliesError, setWebAliesErros,
                isWebAlias, buttonText: "Next", isUserLogin, _validateRefferalFunction, activeStep
            }} />;
            case 2: return <OneTimePass {...{ otp, handleChangeValidate, _handleOtpSubmit, buttonText: 'Next' }} />;
            case 3: return <CheckoutSection {...{
                addToCartProducts, setAddToCartProduct, countryCodes, setOpenMiniCart, activeStep, setActiveStep, comingFrom: section,
                PRODUCTS_ON_CART, AUTOSHIP_PRODUCTS_ON_CART
            }} />;

            default: return 'Unknown step';
        }
    };

    return (
        <div className="form_section">
            {(activeStep === 1)
                ?
                <Formik
                    id={FormId}
                    enableReinitialize
                    initialValues={formData}
                    validationSchema={signUpValidationSchema(1, isUserLogin, isWebAlias)}
                    onSubmit={(values, actions) => _handleSubmit(values, actions)}
                >
                    {(fieldValue) => (
                        <Form>
                            <ConnectedFocusError />
                            {getStepContent(activeStep, fieldValue)}
                        </Form>
                    )}
                </Formik>
                :
                getStepContent(activeStep)
            }

        </div>
    );
};

MainStepperSignUp.defaultProps = {
    section: "normal",
    isWebAlias: false,
    cartProducts: []
}

export default MainStepperSignUp;