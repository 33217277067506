import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

.product-cstm-slider .swiper {
    width: 100%;
}
@media print {

body .print_logo,
body .footer-container,
body .top-header-wrap {
display: none !important;
}

}

.hometop {
    background-position: center 53%;
    background-size: 2030px;

    @media only screen and (max-width: 750px) {
        background-position: 64% 53%;
        background-size: 266%;
    }

    @media only screen and (max-width: 400px) {
        background-size: 366%;
    }
}

.hometop::after {
    background-color: rgba(0,0,0,0.4)
}

.restfull {
    background-position: center 34%;
    background-size: 2030px;

    @media only screen and (max-width: 750px) {
        background-position: 40% 34%;
    }
}

.restfull::after {
    background-color: rgba(0,0,0,0.25)
}

.mental {
    background-position: center 33%;
    background-size: 2030px;
}

.mental::after {
    background-color: rgba(0,0,0,0.2)
}

.optimization {
    background-position: center 41%;
    background-size: 2030px;
}

.optimization::after {
    background-color: rgba(0,0,0,0.1)
}

.athletic {
    background-position: center 39%;
    background-size: 2030px;

    @media only screen and (max-width: 750px) {
        background-position: 30% 39%;
    }
}

.athletic::after {
    background-color: rgba(0,0,0,0.2)
}

.reduced {
    background-position: center 21%;
    background-size: 2030px;

    @media only screen and (max-width: 750px) {
        background-position: 57% 21%;
    }
}

.reduced::after {
    background-color: rgba(0,0,0,0.4)
}

.revitalize {
    background-position: center 37%;
    background-size: 2030px;

    @media only screen and (max-width: 750px) {
        background-position: 70% 37%;
    }
}

.revitalize::after {
    background-color: rgba(0,0,0,0.2)
}

.defense {
    background-position: center 31%;
    background-size: 1930px;

    @media only screen and (max-width: 750px) {
            background-position: 27% 31%;
    }
}

.defense::after {
    background-color: rgba(0,0,0,0.2)
}

.detoxification {
    background-position: center 14%;
    background-size: 2030px;
}

.detoxification::after {
    background-color: rgba(0,0,0,0.3)
}




.cstm-accordion {
    .MuiPaper-root{
        &.MuiAccordion-root {
            margin-bottom: 30px;
            box-shadow: 0px 0px 30px rgba(0,0,0,0.12);
            border-radius: 7px;

            .MuiButtonBase-root {
                padding: 30px;

                .MuiAccordionSummary-content {
                    margin: 0;
                }
            }

            .MuiCollapse-root {
                padding: 0 35px;

                .MuiAccordionDetails-root {
                    padding: 0;
                }
            }
        }
    }
}

.MuiDialog-root {
    .MuiDialog-container {
        align-items: center;
        overflow: auto;

        &.cstm-popup {
            align-items: unset;
        }

        .MuiPaper-root {
            overflow: auto;
            max-height: unset;

            .MuiTypography-root {
                display: flex;
                justify-content: space-between;

                .MuiButtonBase-root {
                    padding: 0;
                }
            }
        }
    }
    .addessForm{
        .MuiPaper-root {
            overflow: auto;
            max-height: unset;
            height: calc(100vh - 64px);
        }
    }
}

button {
    &:focus-visible {
        outline: none;
    }
}
    .invite-referrer {
        width: 100%;
        background: #0071BA;
        border-radius: 8px;
        max-width: 89px;
        color: #fff;
        padding: 10px;
        float: right;
        margin: 22px 0px 7px;
    }
    .invite-referrer.disabled {
            background: #cfd0d1;
            color: #5f5b5bfa;
        }

    h2#alert-dialog-title {
        border-bottom: 1px solid;
    }

    /* p {
        margin: 22px;
    } */

    .blank-section {
        border-bottom: 1px solid;
        padding: 8px;
    }

    /* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
        display: none;
    } */

    .MuiBox-root.css-1iq6qq8 {
        max-width: unset;
    }

    .flayout_cart.MuiDrawer-paperAnchorRight {
        width: 100%;
        max-width: 662px!important;
    }

    .loader-bg {
	background: rgba(255, 255, 255, 0.9);
    margin: 0px !important;
}

    .mini-root-div {
        position: fixed;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        padding: 13px;
        border-radius: 5px 0 0 5px;
        cursor: pointer;
        background: linear-gradient(90deg, #0071BA 44%, rgba(27, 129, 246, 0.308) 100%);
        z-index: 100;
        width: 60px;

        .fa.fa-shopping-cart {
            color: #fff;
            font-size: 24px;
        }
        span {
            position: absolute;
            top: 8px;
            right: 4px;
            background: #0071BA;
            color: #fff;
            font-size: 14px;
            width: 17px;
            height: 17px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 15px;
        }
    }

    .us_store {
        min-height: 80vh;
    }

    .MuiDialog-paper.addProduts {
        max-width: 100%;
        margin: 32px 10px;
    }

    .addProduts .addProduts_main_dialog {
        position: relative;
        height: calc(100vh - 30px)
    }

    .addProduts .addProduts_main_dialog .MuiButtonBase-root {
        position: absolute;
        top: 0;
        right: 0;
    }
    .custom-container {
        background: linear-gradient(90deg, #0071BA 44%, rgba(27, 129, 246, 0.308) 100%);
        svg {
            width:90px;
            height:90px;
        }
    }


    /*headerCSS*/
    .dropdown-menu {
        z-index: 1000;
        top: 100%;
        float: left;
        padding: 0.5rem 0;
        margin: 0.75rem 0 0;
        font-size: 1rem;
        color: #4b566b;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e3e9ef;
        border-radius: 0.3125rem;
        box-shadow: 0 0.25rem 0.5625rem -0.0625rem rgba(0,0,0,0.03), 0 0.275rem 1.25rem -0.0625rem rgba(0,0,0,0.05);
        border-color: #fff;
        position: absolute;
        will-change: transform;
        min-width: 22rem !important;
        top: 0px;
        left: 0px;
        transform: translate3d(-289px, 26px, 0px);

        .dropdown-item {
            display: block;
            width:100%;
            padding: 0.425rem 1.3rem;
            clear: both;
            color: #4b566b;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
            transition: all 0.25s ease-in-out;
            font-size: .875rem;
            font-weight: normal;

            &:hover, &:focus {
                color: #0071BA;
                text-decoration: none;
                background-color: rgba(0,0,0,0);
            }
        }
    }

    .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.3rem;
        vertical-align: middle;
        content: "";
        border-top: 0.275em solid;
        border-right: 0.275em solid transparent;
        border-bottom: 0;
        border-left: 0.275em solid transparent;
        color:#fff;
    }
    .successfull-msg{
    float: right;
    color: #fff;
    margin: 4px;
    background: #00385c;
    width: 10%;
    border-radius: 7px;
    padding: 5px;
    }   
    &.error_wrap {
            select {
                border-color: #d32f2f;
                color: #d32f2f;

                &:focus {
                         outline-color: #d32f2f;
                        }
                    }
                .error {
                color:#d32f2f;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1.66;
                letter-spacing: 0.03333em;
                text-align: left;
                margin-top: 3px;
                margin-right: 14px;
                margin-bottom: 0;
                margin-left: 14px;
            }
    }
/* address dialog */
.addessForm_main_dialog  {
    .MuiDialogTitle-root {
        background-color: #0071BA;
        color: #fff;
        position: relative;
        font-weight: 700;

        button.close_button  {
            top: 12px;
            position: absolute;
            right: 10px;
            background: rgb(255, 255, 255);
            z-index: 9999;
            height: 40px;
            width: 40px;
            border-radius: 100%;
        }
    }
    .container_for_load_data{
        padding: 20px 24px;

        form {
            padding-top: 30px;

            .textField {
                .Mui-error {
                    color: #d32f2f;
                }
                label {
                    color: #0071BA;
                }
            }

            .country_state_main {
                padding:0;
                width: 100%;
                margin-bottom: 30px;

                .select {
                    margin: 0;

                    &.error_wrap {
                        select {
                            border-color: #d32f2f;
                            color: #d32f2f;

                            &:focus {
                                outline-color: #d32f2f;
                            }
                        }
                    }
                        .error {
                            color:#d32f2f;
                            font-family: "Roboto","Helvetica","Arial",sans-serif;
                            font-weight: 400;
                            font-size: 0.75rem;
                            line-height: 1.66;
                            letter-spacing: 0.03333em;
                            text-align: left;
                            margin-top: 3px;
                            margin-right: 14px;
                            margin-bottom: 0;
                            margin-left: 14px;
                        }
                }

                select {
                    width: 100%;
                    border: 1px solid rgba(0, 0, 0, 0.23);
                    padding: 16.5px 14px;
                    height: 56px;
                    border-radius: 4px;

                    &:hover {
                        border-color: rgba(0, 0, 0, 0.87);
                    }
                }
            }
        }
    }
    .MuiDialogContent-root {
        form {
            .MuiInputBase-formContro {
                &.Mui-disabled {
                    border-bottom-style: solid;
                }
            }
            .row-form {
                display: flex;
                gap: 20px;
                margin: 10px 0px;

                .select {
                    margin-bottom: .5rem;
                    width: calc(100%/2 - 10px);
                }
                select {
                    width: 100%;
                    padding: 13px 15px 10px;
                    border: 1px solid rgb(196, 196, 196);
                    border-radius: 5px;
                    font-size: 16px;
                    background-color: transparent;
                    line-height: 1.2;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
        }
    }
}
.incentive_form {
    width: 100%;
}

`;

export default GlobalStyle;