import _ from "lodash";
import { CreateObject } from "../../../services/Methods/checkoutPayloads";
import { CircularProgress } from "@mui/material";
import { FloatValues } from "../../../services/Methods/normalMethods";
import ButtonComponent from "../../../Inputs/ButtonComponent";
import { Delete } from "@mui/icons-material";
import OutlinedButton from "../../../Inputs/OutlinedButton";
import ShippingSection from "../../../Common/Components/AllFormsCommons/ShippingSection";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createSelector } from "reselect";
import { forwardRef, useEffect } from "react";


const AutoshipProductDetail = ({ children, ...props }) => {

    const { selectedDate, setSelectedDate, calculatedAutoshipData, setOpenProductAdd, shippingMethods, _openCloseAskPopup, _handleQuantiyIncDec,
        selectedAutoshipCheck, setFrequencyType, frequencyTYpe, _handleOnSelectedAutoshipChange, AllProductAndCheckoutText, autoshiporder
    } = props;
    const PARENTS_PRODUCT_AUTOSHIP = _.filter(calculatedAutoshipData?.details, (value) => value?.parentItemId === null);
    const AUTOSHIP_PRODUCTS = _.map(PARENTS_PRODUCT_AUTOSHIP, (row) => {
        const values = CreateObject(row);
        return ({
            ...values, subProducts: _.map(_.filter(calculatedAutoshipData?.details, (r) => r?.parentItemId === row?.itemId), (v) => CreateObject(v)),
            autoship: "autoship"
        })
    });

    const SHIPPING_SECTION = [
        { text: "Every 30 Days", value: "1" },
        { text: "Every 60 Days", value: "7" },
    ];

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="example-custom-input text-left w-full p-[10px] tracking-[4px] border border-[#dddd]" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    function isWeekday(date) {
        const day = new Date(date).getDate();
        return !_.includes([29, 30, 31], day);
    }

    const { ShippingSection_TEXTS } = AllProductAndCheckoutText;

    return (
        <div className="main_product_detail_section">
            <div className="addProducts">
                <ButtonComponent classes="addProduct" onClick={() => setOpenProductAdd(true)}>Add Products</ButtonComponent>
            </div>
            <div className="Autoship_heading_section">
                <h3>Products</h3>
            </div>
            <div className="allProduct_listing">
                <div className="product_detail">
                    {(AUTOSHIP_PRODUCTS?.length > 0)
                        ?
                        <table>
                            <thead>
                                <tr>
                                    {/* <th>Image</th> */}
                                    <th>Product Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.map(AUTOSHIP_PRODUCTS, (row) => (
                                    <tr key={row?.id}>
                                        {/* <td></td> */}
                                        <td>{row?.productName}</td>
                                        <td>
                                            <div className="quality-box !text-center">
                                                <OutlinedButton className="w-[85px] sm:w-[55px] md:w-[68px] md:h-[35px] lg:h-[42px] lg:!w-[85px] xl:!w-[110px] hover:!text-black hover:cursor-default px-1 h-[40px]">
                                                    <div className="flex items-center justify-around lg:text-sm xl:text-lg">
                                                        <span
                                                            className="hover:text-primary cursor-pointer py-2 px-2"
                                                            onClick={() => _handleQuantiyIncDec(row?.id, 'sub')}
                                                        >
                                                            -
                                                        </span>
                                                        <span>{row?.quantity}</span>{" "}
                                                        <span
                                                            className="hover:text-primary cursor-pointer py-2 px-2"
                                                            onClick={() => _handleQuantiyIncDec(row?.id, 'add')}
                                                        >
                                                            +
                                                        </span>
                                                    </div>
                                                </OutlinedButton>
                                            </div>
                                        </td>
                                        <td className="!text-center">${FloatValues(row?.price)}</td>
                                        <td className="!text-center">
                                            <span className='delete-icon' onClick={() => _openCloseAskPopup(+row?.id, 'open')}> <Delete /></span>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        :
                        <div className="loader_progress">
                            <CircularProgress />
                        </div>
                    }
                </div>
                <div className="main_shippingAndfrequency">
                    <div className="shippingMethod_section">
                        {children}
                    </div>
                    <div className="frquency_section">
                        <ShippingSection
                            section="Autoship"
                            shippingMethods={shippingMethods}
                            selectedCheck={selectedAutoshipCheck}
                            _handleShippingChange={(data, section) => _handleOnSelectedAutoshipChange(data, section)}
                            ShippingSection_TEXTS={ShippingSection_TEXTS}
                        />
                        {/* <div className="shipping_dropdown">
                            <h3>Change Your Frequency</h3>
                            <SelectComponent
                                name="frequency"
                                classes="frequency_dropdown"
                                options={SHIPPING_SECTION}
                                label=""
                                value={String(frequencyTYpe)}
                                onChange={(e) => setFrequencyType(+e.target.value)}
                            />
                            <p>* you can change your current frequency type.</p>
                        </div> */}
                        <div className='data_change'>
                            <div className='mb-[25px] border-b border-[#000]'>
                                <h3 className='mb-[4px] lg:text-[22px] sm:text-[18px] text-[16px] lg:text-left text-center' style={{ textTransform: "capitalize" }}>
                                    SmartSave Scheduling
                                </h3>
                            </div>
                            <div>
                                <p>Next Recurring Order Date</p>
                                <DatePicker
                                    className='w-full'
                                    selected={selectedDate}
                                    minDate={new Date()}
                                    onChange={(date) => {
                                        setSelectedDate(date);
                                    }}
                                    customInput={<ExampleCustomInput />}
                                    filterDate={isWeekday}
                                />
                            </div>
                        </div>
                        {/* <div className="data_change">
                            <h3>Next Recurring Order Date</h3>
                            <div>
                                <DatePicker className="time_change"
                                showIcon
                                toggleCalendarOnIconClick
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                minDate={new Date()}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="calculationSection">
                    <table>
                        <tbody>
                            <tr><th>Subtotal :</th><td>${parseFloat(calculatedAutoshipData?.subTotal || 0).toFixed(2)}</td></tr>
                            <tr><th>Shipping Amount :</th><td>${parseFloat(calculatedAutoshipData?.shippingTotal || 0).toFixed(2)}</td></tr>
                            <tr><th>Tax Amount :</th><td>${parseFloat(calculatedAutoshipData?.taxTotal || 0).toFixed(2)}</td></tr>
                            <tr><th>Amount Paid :</th><td>${parseFloat(calculatedAutoshipData?.orderTotal || 0).toFixed(2)}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AutoshipProductDetail;