import { createSlice } from '@reduxjs/toolkit';
import api from '../../AxiosHelper';
import { MIDDLE_CREDENTIAL, storeAssigned } from '../../Methods/normalMethods';


const AllProductSlice = createSlice({
  name: "allProductPage",
  initialState: {
    data: [],
    creamData: [],
    paginationData: [],
    IBOProducts: [],
    retailProduct: [],
    prefferedProducts: [],
    totalCount: 0,
    error: '',
    isLoading: false,
    colors: [],
  },
  reducers: {
    setAllProducts: (state, action) => {
      if (action.payload?.section === "IBOProducts") {
        state['IBOProducts'] = action.payload?.res;
      } else if (action.payload?.section === "retailProduct") {
        state['retailProduct'] = action.payload?.res;
      } else if (action.payload?.section === "prefferedProducts") {
        state['prefferedProducts'] = action.payload?.res;
      } else {
        state['data'] = action.payload?.res;
      }
    },
    setIsLoading: (state, action) => {
      state['isLoading'] = action.payload;
    },
    setPaginationData: (state, action) => {
      state['paginationData'] = action.payload;
      state['totalCount'] = action.payload?.totalRecords
    },

  }
});

export const { isLoading, setIsLoading, setAllProducts, setCreamsProducts, setPaginationData } = AllProductSlice.actions;

export const getAllProductApi = (section = "All Products", callback, catId = null) => dispatch => {
  dispatch(setIsLoading(true));
  const COUNTRYID = { us: 1005, ca: 1011, au: 1017, nz: 1023, mx: 1029, ph: 1035, ng: 1041, cd: 1047, cm: 1053, ke: 1059, mu: 1065, gb: 1071 };

  let id = catId || COUNTRYID[storeAssigned()];
  switch (section) {
    case 'retailProduct':
      id = 1005;
      break;
    case 'prefferedProducts':
      id = 1005;
      break;
    case 'IBOProducts':
      id = 1005;
      break;
    case 'All Products':
      id = 1078;
      break;
    case 'GSH':
      id = 1079;
      break;
    case 'Energy':
      id = 1080;
      break;
    case 'Combos':
      id = 1081;
      break;
    case 'Samples':
      id = 1082;
      break;
    case 'Skin Care':
      id = 1083;
      break;
    case 'Mission Promo Packs':
      id = 1084;
      break;
    case 'Rave':
      id = 1077;
      break;

  }
  api().setHeaders(MIDDLE_CREDENTIAL).post().data({
    method: "GET",
    endpoint: `/Crm/Items/WebCategory/${id}?hideFromWeb=eq:false`
  }).success((res) => {
    if (res?.data) {
      dispatch(setAllProducts({ res: res?.data, section }));
      if (callback) {
        callback(res?.data);
      }
      dispatch(setIsLoading(false));
    }
  }).error((err) => {
    dispatch(setIsLoading(false));
  }).send()
};

export const getAllProductApiPagination = (currentpage, section) => dispatch => {
  dispatch(setIsLoading(true));
  let id = 1002;
  switch (section) {
    case 'Lips':
      id = 1000;
      break;
    case 'Nails':
      id = 1001;
      break;
    case 'Face':
      id = 1002;
      break;
    case 'Wellness':
      id = 1003;
      break;
    case 'Beauty':
      id = 1003;
      break;
    case 'Skincare':
      id = 1004;
      break;
    case 'Mission Promo Packs':
      id = 1084;
      break;
    case 'Longevity':
      id = 1005;
      break;
  }
  api().setHeaders(MIDDLE_CREDENTIAL).post().data({
    method: "GET",
    endpoint: `/Crm/Items/WebCategory/${id}?hideFromWeb=eq:false&Limit=12&Offset=${currentpage}`
  }).success((res) => {
    if (res?.data) {
      dispatch(setPaginationData(res));
      dispatch(setIsLoading(false));
    }
  }).error((err) => {
    dispatch(setIsLoading(false));
  }).send()
};


export default AllProductSlice.reducer;
