import styled from "styled-components";

const AutoshipMainPageStyle = styled.div`
.data_change .react-datepicker-wrapper{
    margin-top: 15px;

    input {
        border: 1px solid;
    }
}
    .main_autoship_edit {
        padding-top: 50px;
        @media (max-width:1199px) {
            padding-top: 30px;
        }

        .autohsip_banner {
            padding: 10px;
            border-bottom: 1px solid #0171ba;            
            text-align: center;

            h2 {
                color: #0171ba !important;
                font-size: 60px;
                margin-bottom: 0px;
            }
        }

        .contents {
            margin-top: 40px;
            .main_address_detail_section {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 40px;

                .shipping_address_bar {
                    width: 100%;
                    text-align: center;
                    background: #0171ba;

                    h3 {
                        color: #fff;
                        margin-bottom: 0px;
                        padding: 5px 0;
                        font-size: 22px;
                        line-height: 32px;
                    }
                }

                .shipping_details_table {
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .main-table-data {
                        width: 100%;
                        max-width: 80%;
                        table {
                            &#mobile_table{
                                display: none;
                            }
                        width: 100%;
                            tbody {
                                tr  {
                                    th,td {
                                        border: 1px solid #0171ba !important;
                                        padding: 10px;
                                    }
                                    th{
                                        color: #0171ba;
                                    }
                                    td {

                                    }
                                }
                            }
                        }
                        @media screen and (max-width: 601px){
                            flex-direction: column;
                            &#desktop_table{
                                display: block;
                            }
                            &#mobile_table{
                                display: none;
                            }
                            tr {
                                display: flex;

                                th,td {
                                    flex: 0 0 50%;
                                }
                                td {
                                    text-align: center;
                                }
                            }
                        }
                        @media screen and (max-width: 600px){
                            flex-direction: column;
                            &#desktop_table{
                                display: none;
                            }
                            &#mobile_table{
                                display: block;
                            }
                            tr {
                                display: flex;

                                th,td {
                                    flex: 0 0 50%;
                                }
                                td {
                                    text-align: center;
                                }
                            }
                        }
                    }

                    
                    .action_button {
                        width: 100%;
                        max-width: 20%;
                        text-align: center;
                        /* margin: 30px 0; */

                        button {
                            padding: 10px 32px;
                            border-radius: 30px;
                            font-size: 20px;
                            border: unset;
                            color: #fff;
                            background: #0171ba;
                        }
                    }

                    @media(max-width: 768px) {
                        flex-direction: column;
                        table {
                            max-width: 100%;
                        }
                        .action_button {
                            max-width: 30%;
                            @media (max-width:767px){
                                max-width: 100%;
                            }
                            p {
                                margin: 0px;
                            }
                        }
                    }
                }
            }
            .main_product_detail_section {
                .addProducts {
                    text-align: right;
                    margin-top: 20px;
                    
                    button {
                        width: 190px;
                        height: unset;
                        padding: 8px;
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
                .Autoship_heading_section {
                    margin-top: 20px;
                    text-align: left;
                    padding: 5px 0px;
                    border-bottom: 2px solid #DDDD;

                    h3 {
                        color: rgb(68 53 53);
                        margin-bottom: 0px;
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 32px;
                    }
                }
                .allProduct_listing {
                    .product_detail {
                        width: 100%;
                        .loader_progress {
                            text-align: center;
                            padding: 20px;
                        }
                        table {
                            width: 100%;
                            margin-top: 8px;
                            tbody, thead {
                                tr  {
                                    border-bottom: 1px solid #DDD;
                                    text-align: center;
                                    th,td {
                                        /* border: 1px solid #0171ba !important; */
                                        padding: 10px;
                                    }
                                    th{
                                        font-size: 18px;
                                        color: #2a2727;
                                        font-weight: 400;

                                        &:first-child {
                                            text-align: left;
                                        }
                                    }
                                    td {
                                        &:first-child {
                                            text-align: left;
                                        }

                                        &:nth-child(2) {
                                            text-align: center;
                                        }
                                        .quality-box {
                                            justify-content: center;
                                            .input-group {
                                                width: 100%;
                                                margin-top: 0px !important;
                                                max-width: 130px;
                                                button {
                                                    &:first-child {
                                                        margin-right: 0px;
                                                    }
                                                    &:last-child {
                                                        margin-left: 0px;
                                                    }
                                                }
                                            }
                                        }
                                        .delete-icon {
                                            svg {
                                                color: #ff0000c7;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .main_shippingAndfrequency {
                        margin-top: 30px;
                        display: flex;
                        width: 100%;
                        
                        justify-content: space-between;
                        /* gap: 20px; */

                        @media only screen and (max-width: 1023px) {
                            flex-wrap: wrap;
                            gap: 30px;
                        }

                        .shippingMethod_section ,.frquency_section {
                            width: 100%;
                            h3 {
                                padding: 5px 10px;
                                margin-bottom: 0px;
                                background: #0171ba;
                                color: #fff;
                                font-size: 20px;
                            }
                        }
                        .shippingMethod_section {
                            max-width: 65%;

                            @media only screen and (max-width: 1023px) {
                                max-width: 100%;
                            }
                        }
                        .frquency_section {
                            max-width: 30%;
                            display: flex;
                            flex-direction: column;
                            row-gap: 30px;

                            @media only screen and (max-width: 1023px) {
                                max-width: 100%;
                                row-gap: 0px;
                            }

                            .shipping_dropdown {
                                /* height: 100%; */
                                min-height: 128px;
                                border: 1px solid #0171ba;

                                .select_component {
                                    width: 100%;
                                    margin: 0;
                                    padding-top: 15px;

                                    .frequency_dropdown {
                                        border: 1px solid #0171ba;
                                        width: 100%;
                                        max-width: 280px;
                                        text-align: center;
                                        margin: 0 auto;
                                    }
                                }

                                p {
                                    text-align: center;
                                    margin-top: 8px;
                                    font-size: 13px;
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .shippingMethod_section {
                            .shipping_option_div {
                                .shipping_methodOptions {
                                    padding: 0 10px;
                                    border: 1px solid #0171ba;
                                }

                            }
                        }
                        
                        @media(max-width: 575px){
                            flex-direction: column;

                            .shippingMethod_section,.frquency_section {
                                max-width: 100%;
                            }
                        }
                    }

                    .calculationSection {
                        display: flex;
                        justify-content: end;
                        margin-top: 20px;
                        padding-left: 20px;

                        @media only screen and (max-width: 1023px) {
                            margin-top: 30px;
                            padding-left: 0px;
                        }

                        table {
                            width: 100%;
                            max-width: 50%;

                            @media only screen and (max-width: 1023px) {
                                max-width: 100%;
                            }

                            tbody, thead {
                                tr  {
                                    @media only screen and (max-width: 600px) {
                                        border-bottom: none;
                                        display: table-row;
                                        margin-bottom: 0;
                                    }
                                    th,td {
                                        border: 1px solid #0171ba !important;
                                        padding: 10px;
                                    }
                                    th{
                                        color: #0171ba;
                                    }
                                    td {
                                        @media only screen and (max-width: 600px) {
                                            display: table-cell;
                                        }
                                    }
                                }
                            }
                        }
                        @media(max-width: 575px){
                            padding-left: 0px;
                           table {
                            max-width: 100%;
                           }
                        }
                    }
                }
            }
            .main_PaymentSaved_section {
                margin-top: 30px;

                .payment_heading {
                    margin-top: 20px;
                    text-align: center;
                    background: #0171ba;
                    padding: 5px 0px;

                    h3 {
                        color: rgb(255, 255, 255);
                        margin-bottom: 0px;
                        font-size: 22px;
                        line-height: 32px;
                    }
                }
                .PaymentSaved_css {
                    display: flex;
                    margin-top: 20px;
                    width: 100%;
                    gap: 20px;

                    h3 {
                        border-bottom: 3px solid #0171ba;;
                        width: 100%;
                        max-width: 245px;
                        margin-bottom: 15px;
                    }

                    #collect-form_pay {
                        iframe {
                            margin-bottom: 0px;
                            height: 60px !important;
                        }
                    }

                    .saved_add_section{
                        flex: 0 0 48%;
                        .neweletter-subscrib{
                            input{
                                width: 24px;
                                height: 24px;
                                margin-right: 10px;
                                position: relative;
                            }
                        }
                    }
                     .payment_fields {
                        flex: 0 0 50%;
                    }
                }
                @media(max-width: 575px){
                   .PaymentSaved_css {
                        flex-direction: column;
                   }
                }
            }
            @media( max-width: 1280px ){
                button {
                    font-size: 16px !important;
                }
            }
        }
        .update_autoship {
            text-align: center;
            margin: 20px 0px 10px;
            
            button {
                width: 190px;
                height: unset;
                padding: 8px;
                font-size: 20px;
            }
        }
        .errorMsg {
            margin-top: 10px;
            text-align: center;
            color: #f00;
        }
    }

@media screen and (max-width: 600px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }
    
    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    
    table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
        th,td {
            font-size: 14px;
        }
    }
    
    table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
        .quality-box {
            justify-content: flex-end;
        }
    }
    
    table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
        color: #0171ba;
    }
    
    table td:last-child {
        border-bottom: 0;
    }
}    


@media(max-width:568px){
    .product_detail th , .shipping_details_table .main-table-data th {
        min-width: 190px;
    }
    .product_detail  {
        overflow-x: scroll;
        width: auto;
        max-width:522px;
    }
    .shipping_details_table .main-table-data {
        overflow-x: scroll;
        width: auto;
        max-width:522px;
    }
}
 @media(max-width:550px){
    .shipping_details_table .main-table-data {
        max-width: 500px !important;
    }
}
@media(max-width:520px){
    .shipping_details_table .main-table-data {
        max-width: 464px !important;
    }

}
@media(max-width:490px){
 .shipping_details_table .main-table-data {
    max-width: 430px !important;
}
.shipping_details_table .main-table-data th {
    min-width: 120px;
}
}
@media(max-width:450px){
.shipping_details_table .main-table-data {
    max-width: 360px !important;
}
}
@media(max-width:400px){
.shipping_details_table .main-table-data {
    max-width: 317px !important;
}
}
`;

export default AutoshipMainPageStyle;