import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { createSelector } from 'reselect';
import { calculateDataUserPayload, calculateReouccringPayload, _checkoutAddressFormat, _paymentPayload, _priceTypeForCalculate } from "../Methods/checkoutPayloads";
import { loopFunction, USERDATA_PAYLOAD, _scriptFunctionCall, PaymentOptionIDData } from "../Methods/commonPayload";
import localStorageCall from "../Methods/localstorage.hook";
import { _setTimeOut } from "../Methods/normalMethods";
import { _formDefaultFormat } from "../Methods/validationSchema";
import { CalculateApi, CalculateAutoshipApi, CreateAutoshipOrderApi, CreateOrderApi } from "../Redux/Reducer/CheckoutSlice";
import { getCustomerSavedCard } from "../Redux/Reducer/CustomerSlice";
import { AuthenticateProfileCode, createMerchantProfile, customerUpdateApi, ValidateCustomerProfile } from "../Redux/Reducer/LoginSlice";
import { _getPaymentHtml, _getPaymentOptions } from "../Redux/Reducer/PaymentSlice";

const withCheckoutHOC = (Component) => {

    const WithCheckoutHOComponent = (props) => {

        const { comingFrom, addToCartProducts, setAddToCartProduct } = props;
        const { dispatch, userData, navigate, ROUTING_TEXT, AllProductAndCheckoutText, autoshipOrders } = useOutletContext();
        let Dates = _.pick(userData, ['distributorDate', 'signUpDate', 'entryDate']);
        const isUserLogin = localStorageCall().getSingleItem('Token');
        const memorizeSelector = createSelector(
            (state) => state.PaymentSlice,
            (state) => state.CustomerSlice,
            (state) => state?.CheckoutSlice,
            (data, customerData, checkoutData) => {
                const { paymentOptions, paymentFormHtml, iframeLoader, paymentError } = data;
                const { error, errorAddressData, normalAddressError, couponErrorData, shippingLoading, isLoading } = checkoutData
                return ({
                    couponErrorData, paymentError,
                    paymentOptions, paymentFormHtml, iframeLoader,
                    savedCards: customerData?.savedCards,
                    error, errorAddressData, normalAddressError, isLoading, shippingLoading
                })
            }
        );

        const {
            paymentOptions, paymentFormHtml, savedCards, iframeLoader, error, couponErrorData,
            errorAddressData, normalAddressError, isLoading, shippingLoading, paymentError
        } = useSelector((state) => memorizeSelector(state));

        const [formData, setFormData] = useState(USERDATA_PAYLOAD);

        const [userDetails, setUserDetails] = useState(null);

        const [otp, setOtp] = useState(new Array(6).fill(""));
        const [countryState, setCountryState] = useState({
            country: "", countryError: "",
            state: "", stateError: "",
        });
        const [savedThisCard, setSavedThisCard] = useState({ checked: true, readOnly: false });

        const [useDefaultAddressOrNot, setUseDefaultAddressOrNot] = useState(null);
        const [selectedPayment, setSelectedPayment] = useState({
            cardValue: null,
            selectedPaymentDropdown: null,
            selectedOptionForPayment: 2
        });

        const [calculatedData, setCalculatedData] = useState({
            autoshipCalculateData: null,
            oneTimeCalculateData: null
        });

        const [shippingMethods, setShippingMethods] = useState({
            autoshipShipMethod: [],
            oneTimeShipMethod: []
        });
        const [selectedShippingOption, setSelectedShippingOption] = useState({
            section: comingFrom,
            autoshipSelectedShipping: { shipCarrierId: null, shipMethodType: null },
            oneTimeSelectedShipping: { shipCarrierId: null, shipMethodType: null }
        });

        const [onSelectedCardError, setOnSelectedCardError] = useState('');
        const [checkValidUser, setCheckValidUser] = useState(false);
        const [otpPopUp, setOtpPopUp] = useState(false);
        const [storeToken, setStoreToken] = useState({ token: null, error: "" });

        const [usePoint, setUsePoint] = useState({
            wantToUSePoint: false,
            paymentObject: { currencyCode: "USD", maxAmount: '', pointAccountType: 1, PaymentType: 2 }
        });
        const [couponCode, setCouponCode] = useState({ code: "", valid: false });
        const [scriptError, setScriptError] = useState('')
        const [askPopupDlocal, setAskPopupDlocal] = useState(false);

        let calculteDataUser = calculateDataUserPayload(formData, selectedShippingOption, useDefaultAddressOrNot);
        let calculateAutoshipData = calculateReouccringPayload(formData, selectedShippingOption, useDefaultAddressOrNot);

        const MEMBERSHIP_AVAILABLE = _.find(localStorageCall().getItem(comingFrom + 'Products'), { member: true }) || null;
        console.log("MEMBERSHIP_AVAILABLE", MEMBERSHIP_AVAILABLE);
        const _productPayload = (data, forWhat = "oneTime") => _.compact(_.map(data, (item, index) => {
            if (item?.id) {
                return ({
                    parentItemId: 0,
                    orderLineNumber: index + 1,
                    itemId: item?.id,
                    itemCode: item?.itemCode,
                    quantity: item?.quantity,
                    ...(forWhat !== "oneTime") ? { "parentLineNumber": 0 } : {}
                })
            }
        }));
        console.log(selectedPayment?.cardValue, "selectedpayment>>>>>>>>>>>>>>>>>>>>>>>>>>");

        // validate user
        function _createMarchentProfile(data, callback) {
            const token = localStorageCall().getSingleItem('Token');
            dispatch(createMerchantProfile(token, data, (row) => {
                if (row === null) {
                    setStoreToken({ token: null, error: row?.errorMessage });
                } else {
                    if (row === "VALID") {
                        setCheckValidUser(true);
                        _setTimeOut(() => callback(), 3000)
                    } else {
                        setOtpPopUp(true);
                        setStoreToken({ token: row, error: "" });
                    }
                }
            }))
        };

        function _authenticateOtp(callback, flag = false) {
            const token = localStorageCall().getSingleItem('Token');
            if (flag) {
                setCheckValidUser(true);
                setOtpPopUp(false);
                _setTimeOut(() => callback(), 3000);
            } else {
                if (otp?.join('') === '121212') {
                    dispatch(AuthenticateProfileCode(token, storeToken?.token, otp?.join(''), (data) => {
                        if (data) _authenticateOtp(callback, true);
                    }))
                } else {
                    _authenticateOtp(callback, true);
                }
            }
        }

        const handleChangeValidate = (element, index) => {
            if (isNaN(element.value)) return false;
            setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        };

        /******************************************************************************************************************/

        // coupon functionality
        function _onCouponClick(action) {
            if (couponCode?.code) {
                calculteDataUser['couponCodes'] = (action === 'add') ? [couponCode?.code] : [''];
                _calculateApiCall('coupon');
            }
        }

        //checkout main calculation functionality
        function _handleCommonLoader(data, frequency = "oneTime") {
            if (data) {
                setCalculatedData((prv) => ({ ...prv, [`${frequency}CalculateData`]: data?.crmOrder }));

                let CHECK = !_.some(_.map(data?.shipMethods, 'shipMethodDescription'), (r) => r === null);
                setShippingMethods((prv) => ({ ...prv, [`${frequency}ShipMethod`]: CHECK ? data?.shipMethods : [] }));
                setSelectedShippingOption((prv) => ({
                    ...prv, section: comingFrom,
                    [`${frequency}SelectedShipping`]: {
                        shipCarrierId: CHECK ? data?.crmOrder?.shipCarrierId : null,
                        shipMethodType: CHECK ? data?.crmOrder?.shipMethodType : null
                    }
                }));
            }
        }
        // calling function for calculate api
        function _calculateApiCall(section) {
            const FILTER_PRODUCTS = addToCartProducts[comingFrom + 'Products'];
            const detailsArray = _productPayload(FILTER_PRODUCTS);
            const autoshipProduct = _productPayload(_.filter(FILTER_PRODUCTS, ({ frequency, ...row }) => (frequency === "autoship" && !row?.member)));
            const MEMBER_SHIP_PRODUCT = _productPayload(_.filter(FILTER_PRODUCTS, ({ frequency, ...row }) => row?.member));

            if (autoshipProduct?.length > 0 || MEMBERSHIP_AVAILABLE) {
                setSavedThisCard((prv) => ({ ...prv, readOnly: true }))
            }

            if (detailsArray?.length > 0) {                                           /*************** normal order */
                calculteDataUser["details"] = detailsArray;
                calculteDataUser['priceType'] = _priceTypeForCalculate(comingFrom, autoshipProduct, autoshipOrders)
                dispatch(CalculateApi(calculteDataUser, (data) => {
                    if (section === 'coupon') {
                        setCouponCode({ ...couponCode, valid: (action === 'add') && !_.isNull(data?.crmOrder?.couponCodes) ? true : false });
                    }
                    if (data?.name === "addressOption") {
                        setUseDefaultAddressOrNot(data?.addresses);
                    }
                    _handleCommonLoader(data);
                }))
            }
            if (autoshipProduct?.length > 0) {                                        /************** autoship order */
                calculateAutoshipData['details'] = autoshipProduct;  /** autoship order */
                calculateAutoshipData['priceType'] = _priceTypeForCalculate(comingFrom, autoshipProduct, autoshipOrders)
                dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
            }
            if (MEMBER_SHIP_PRODUCT?.length > 0) {                                        /************** Membership order */
                calculateAutoshipData['details'] = MEMBER_SHIP_PRODUCT;  /** Membership order */
                calculateAutoshipData['priceType'] = _priceTypeForCalculate(comingFrom, MEMBER_SHIP_PRODUCT)
                dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'membership')));
            }
        }

        // On field onBlur call function
        function calculateNormalOrder(event, values) {
            const { country, state } = countryState;
            let USER_DETAILS = {};
            if (event) {
                const { name, value } = event.target;
                USER_DETAILS = { ...values, shipping_country: country, shipping_state: state, [name]: value };
                setFormData(USER_DETAILS);
            } else {
                USER_DETAILS = values;
            }
            calculteDataUser = calculateDataUserPayload(USER_DETAILS, selectedShippingOption, useDefaultAddressOrNot)
            calculateAutoshipData = calculateReouccringPayload(USER_DETAILS, selectedShippingOption, useDefaultAddressOrNot);
            if ((USER_DETAILS?.shipping_street && USER_DETAILS?.shipping_country && USER_DETAILS?.shipping_state && USER_DETAILS?.shipping_city && USER_DETAILS?.shipping_zipcode)) {
                _calculateApiCall();
            }
        }

        // shipping change function
        function _handleShippingChange(data, section = "Today's order") {
            const FREQUENCY = (section === "Today's order") ? 'oneTime' : 'autoship';

            if (FREQUENCY) {
                const SELECTED_SHIPPING = { shipCarrierId: data?.shipCarrierId, shipMethodType: data?.shipMethodType };
                setSelectedShippingOption((prv) => ({ ...prv, [`${FREQUENCY}SelectedShipping`]: SELECTED_SHIPPING }));
                if (FREQUENCY === 'oneTime') {
                    calculteDataUser['shipMethod'] = _.assign(calculteDataUser?.shipMethod, SELECTED_SHIPPING);
                    calculateAutoshipData['shipMethod'] = _.assign(calculateAutoshipData?.shipMethod, selectedShippingOption?.autoshipSelectedShipping);

                } else {
                    calculteDataUser['shipMethod'] = _.assign(calculteDataUser?.shipMethod, selectedShippingOption?.oneTimeSelectedShipping);
                    calculateAutoshipData['shipMethod'] = _.assign(calculateAutoshipData?.shipMethod, SELECTED_SHIPPING);
                }
                _calculateApiCall();
            }
        }

        function _setUseDefaultAddressFunction(userData, useDefaultAddressOrNot, formData) {

            setCountryState((prv) => (
                {
                    country: useDefaultAddressOrNot?.country || formData?.shipping_country || prv?.country || userData?.countryCode,
                    state: useDefaultAddressOrNot?.state_region || formData?.shipping_state || prv?.state || userData?.shippingAddress?.regionProvState
                }
            ));
            const CHANGE_COUNTRY = formData?.shipping_country ? { country: formData?.shipping_country, state: formData?.shipping_state } : countryState;
            const SHIPPING_DETAIL = _checkoutAddressFormat(userData, formData, useDefaultAddressOrNot, CHANGE_COUNTRY);
            setUserDetails(_formDefaultFormat(userData));
            setFormData(SHIPPING_DETAIL);
            calculteDataUser = calculateDataUserPayload(SHIPPING_DETAIL, selectedShippingOption, useDefaultAddressOrNot)
            calculateAutoshipData = calculateReouccringPayload(SHIPPING_DETAIL, selectedShippingOption, useDefaultAddressOrNot,);
            _calculateApiCall();

            dispatch(getCustomerSavedCard(isUserLogin, (values) => {
                dispatch(_getPaymentOptions((data) => {
                    if (values?.length > 0 && selectedPayment?.cardValue === null) {
                        setSelectedPayment((prv) => ({ ...prv, cardValue: values[0], selectedOptionForPayment: 1, selectedPaymentDropdown: 0 }));
                        setPaymentOption_ID(PaymentOptionIDData);
                        dispatch(_getPaymentHtml(0));

                    }
                }))
            }));

        }

        function _updateDataOfUser(useDefaultAddressOrNot = null) {
            if (userData?.emailAddress) {
                if (comingFrom !== 'normal') {
                    _setUseDefaultAddressFunction(userData, useDefaultAddressOrNot, formData)
                }
            }
        }
        useEffect(() => {
            _updateDataOfUser(useDefaultAddressOrNot);
        }, [userData])


        useEffect(() => {
            dispatch(_getPaymentOptions((data) => {
                if (data[0]?.paymentOptionId) {
                    setSelectedPayment((prv) => ({ ...prv, selectedPaymentDropdown: data[0]?.paymentOptionId }));
                    dispatch(_getPaymentHtml(data[0]?.paymentOptionId));
                }
            }));
        }, [])
        useEffect(() => {
            if (userData?.emailAddress) {
                dispatch(ValidateCustomerProfile(isUserLogin, (data) => {
                    if (data === null) {
                        setCheckValidUser(false);
                    } else {
                        if (data === "VALID") {
                            setCheckValidUser(true);
                        } else {
                            setCheckValidUser(false);
                        }
                    }
                }));
                dispatch(_getPaymentOptions((data) => {
                    if (data[0]?.paymentOptionId) {
                        setSelectedPayment((prv) => ({ ...prv, selectedPaymentDropdown: data[0]?.paymentOptionId }));
                        dispatch(_getPaymentHtml(data[0]?.paymentOptionId));
                    }
                }));
                _updateDataOfUser(useDefaultAddressOrNot);
            }
        }, [userData?.emailAddress]);

        // **************** payment section **********************
        const [paymentOption_ID, setPaymentOption_ID] = useState(null);

        const [sameAsBillingChecked, setSameAsBillingChecked] = useState(false);

        function _paymentOptionSetBilling(address) {
            console.log(address);
            try {
                window.paymentOption?.setBilling({
                    street: address?.shipping_street,
                    city: address?.shipping_city,
                    region: address?.shipping_state,
                    postal_code: address?.shipping_zipcode,
                    country: address?.shipping_country
                });
            } catch (e) {
                console.log(e);
            }

        }
        function _handleDlocalPopup(action = 'close') {
            if (action === 'confirm') {
                const autoshipProduct = _.filter(addToCartProducts[comingFrom + 'Products'], ({ frequency }) => (frequency === "autoship"));
                const PRODUCTS = _.uniqBy([
                    ..._.reject(addToCartProducts[comingFrom + 'Products'], { frequency: "autoship" }),
                    ..._.map(autoshipProduct, (r) => ({ ...r, frequency: 'oneTime' }))], 'id')
                localStorageCall().setItem(String(comingFrom + 'Products'), JSON.stringify(PRODUCTS));
                setAddToCartProduct((prv) => ({ ...prv, [comingFrom + 'Products']: localStorageCall().getItem(String(comingFrom + 'Products')) }))
                calculateAutoshipData['details'] = [];  /** autoship order */
                dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
                setSelectedPayment((prv) => ({ ...prv, cardValue: null, selectedPaymentDropdown: paymentOption_ID?.paymentOptionId }));
                dispatch(_getPaymentHtml(paymentOption_ID?.paymentOptionId));
            } else {
                dispatch(_getPaymentHtml(selectedPayment?.selectedPaymentDropdown));
            }
            setAskPopupDlocal(false);
        }

        function _handleCardChange(data, section) {
            // const DATA_OF_PAYMENT_OPTION = _.find(paymentOptions, { paymentOptionId: data });
            let DATA_OF_PAYMENT_OPTION = [];
            if (data !== 0) {

                DATA_OF_PAYMENT_OPTION = _.find(paymentOptions, { paymentOptionId: data });
            } else {
                DATA_OF_PAYMENT_OPTION = PaymentOptionIDData
            }


            if (section === "savedCard") {
                // console.log(113, "113");

                setSelectedPayment((prv) => ({ ...prv, cardValue: data, selectedPaymentDropdown: 0 }));
            }
            else if (section === "paymentOption0") {
                // console.log(114, "114");

                setSelectedPayment((prv) => ({ ...prv, selectedPaymentDropdown: 0 }));
                setPaymentOption_ID(DATA_OF_PAYMENT_OPTION);
                dispatch(_getPaymentHtml(data));
            }
            else {
                if (data) {
                    // console.log(115, "115");
                    const autoshipProduct = _.filter(addToCartProducts[comingFrom + 'Products'], ({ frequency }) => (frequency === "autoship"));
                    setPaymentOption_ID(DATA_OF_PAYMENT_OPTION);

                    if (!DATA_OF_PAYMENT_OPTION?.recurringSupported && autoshipProduct?.length > 0) {
                        // console.log(116, 116);

                        setAskPopupDlocal(true);
                    } else {
                        // console.log(117, 117);
                        setSelectedPayment((prv) => ({ ...prv, selectedPaymentDropdown: data }));
                        dispatch(_getPaymentHtml(data));
                    }
                }
            }

        }

        function callBackAutoshipFunction(AutoshipProduct, values, orderid, sucess = false, callBack, autoShipToken, accountId) {

            if (AutoshipProduct?.length > 0) {
                _getPaymentOptions
                try {
                    _onHandleSubmit
                    let autoshipCartPayload = _paymentPayload(null, values, 'autoship', selectedPayment);
                    var date = new Date();
                    date.setDate(date.getDate() + (!sucess ? 30 : 60))
                    calculateAutoshipData['startDate'] = moment(date).utc().format();
                    calculateAutoshipData['frequencyType'] = !sucess ? 1 : 7;
                    calculateAutoshipData["details"] = AutoshipProduct;
                    calculateAutoshipData['priceType'] = _priceTypeForCalculate(comingFrom, AutoshipProduct, autoshipOrders)

                    // if (selectedPayment?.cardValue && selectedPayment?.selectedOptionForPayment === 1) {
                    //     console.log("enter in ifCondition");

                    //     autoshipCartPayload['token'] = selectedPayment?.cardValue?.token;
                    //     autoshipCartPayload['customerAccountId'] = selectedPayment?.cardValue?.customerAccountId;
                    //     autoshipCartPayload['recurringPaymentActionType'] = 2;
                    //     calculateAutoshipData['Payments'] = [{ ...autoshipCartPayload, "maxAmount": +calculatedData?.autoshipCalculateData?.orderTotal }];
                    //     dispatch(CreateAutoshipOrderApi(calculateAutoshipData, comingFrom, orderid, callBack));
                    //     console.log("enter in  last ifCondition");

                    // } else {
                    autoshipCartPayload["token"] = selectedPayment?.cardValue?.token || autoShipToken;
                    autoshipCartPayload['customerAccountId'] = selectedPayment?.cardValue?.customerAccountId || accountId;
                    autoshipCartPayload['recurringPaymentActionType'] = 0;
                    calculateAutoshipData['Payments'] = [{ ...autoshipCartPayload, "maxAmount": +calculatedData?.autoshipCalculateData?.orderTotal, }];
                    calculateAutoshipData['customerId'] = localStorageCall().getItem('Token') || 0,
                        dispatch(CreateAutoshipOrderApi(calculateAutoshipData, comingFrom, orderid, callBack));

                    // _scriptFunctionCall(paymentFormHtml, () => {
                    //     console.log("enter in  scriptFunction");

                    //     loopFunction(paymentFormHtml, ({ tokenId }) => {
                    //         console.log("enter in  LoopFunction");

                    //         if (tokenId) {
                    //             console.log("enter in TokenIdcondition");



                    //         }
                    //     })

                    // }, autoshipCartPayload);
                    // }
                } catch (e) {
                    console.log(e)
                }

            }
        }

        const _membershipApiCall = (orderid, values, autoshipProduct, callMember = null, autoShipToken, accountId) => {
            try {
                // let membershipPayload = _paymentPayload(null, values, 'autoship', selectedPayment);
                // if (selectedPayment?.cardValue && selectedPayment?.selectedOptionForPayment === 1) {
                //     membershipPayload = {
                //         ...membershipPayload,
                //         saveToken: false,
                //         token: selectedPayment?.cardValue?.token,
                //         customerAccountId: selectedPayment?.cardValue?.customerAccountId,
                //         recurringPaymentActionType: 0
                //     }
                // } else {
                //     membershipPayload["token"] = selectedPayment?.cardValue?.token || autoShipToken;
                //     membershipPayload['customerAccountId'] = selectedPayment?.cardValue?.customerAccountId || accountId;
                //     membershipPayload['saveToken'] = paymentOption_ID?.paymentOptionTypeDescription === 'Offline' ? false : (autoshipProduct?.length > 0) ? true : savedThisCard?.checked;
                //     membershipPayload['recurringPaymentActionType'] = 0;
                // }
                if (callMember?.token) {
                    var date = new Date();
                    let ADD_DAYS = 365;
                    date.setDate(date.getDate() + ADD_DAYS);
                    calculateAutoshipData = {
                        ...calculateAutoshipData,
                        startDate: moment(date).utc().format(),
                        frequencyType: 2,
                        priceType: _priceTypeForCalculate(comingFrom, autoshipProduct),
                        recurringOrderType: 3,
                        details: _productPayload([MEMBERSHIP_AVAILABLE]),
                        payments: [{ ...callMember, "maxAmount": MEMBERSHIP_AVAILABLE?.[comingFrom + '_autoship'] }],
                        customerId: localStorageCall().getItem('Token') || 0,
                        // Payments: []
                    };
                    dispatch(CreateAutoshipOrderApi(calculateAutoshipData, comingFrom, orderid,
                        autoshipProduct?.length > 0
                            ?
                            () => {
                                callBackAutoshipFunction(autoshipProduct, values, orderid, false, null, null, 'membership', autoShipToken, accountId)
                            }
                            :
                            null
                    ))
                } else {
                    let membershipPayload = _paymentPayload(null, values, 'autoship', selectedPayment);
                    if (selectedPayment?.cardValue && selectedPayment?.selectedOptionForPayment === 1) {
                        membershipPayload = {
                            ...membershipPayload,
                            saveToken: false,
                            token: selectedPayment?.cardValue?.token,
                            customerAccountId: selectedPayment?.cardValue?.customerAccountId,
                            recurringPaymentActionType: 0
                        }
                        _membershipApiCall(orderid, values, autoshipProduct, membershipPayload, autoShipToken, accountId)
                    }
                    else {
                        if (selectedPayment?.selectedOptionForPayment === 1) {
                            setOnSelectedCardError('Please select your payment method.');
                            _setTimeOut(() => setOnSelectedCardError(''), 3000);
                        } else {
                            _scriptFunctionCall(paymentFormHtml, () => {
                                // setCheckoutProcessing(true)
                                loopFunction(paymentFormHtml, ({ tokenId, preAuthOrderId }) => {
                                    membershipPayload["token"] = tokenId;
                                    // membershipPayload['customerAccountId'] = selectedPayment?.cardValue?.customerAccountId || accountId;
                                    membershipPayload['saveToken'] = (autoshipProduct?.length > 0) ? true : savedThisCard?.checked;
                                    membershipPayload['recurringPaymentActionType'] = 0;
                                    _membershipApiCall(orderid, values, autoshipProduct, membershipPayload, autoShipToken, accountId)
                                })
                            }, membershipPayload);
                        }
                    }
                }
            } catch (err) {
                console.log(err, 'dddddddddddddddddddddddd')
            }
        }

        const _onHandleSubmit = async (values, simplecheckoutPayload = null, orderId = 0, preAuthOrderId = 0) => {
            setScriptError('');
            const FILTER_PRODUCTS = addToCartProducts[comingFrom + 'Products'];
            const detailsArray = _productPayload(FILTER_PRODUCTS);
            const autoshipProduct = _productPayload(_.filter(FILTER_PRODUCTS, ({ frequency, ...row }) => (frequency === "autoship" && !row?.member)));
            let checkoutPayload = _paymentPayload(null, values, 'normal', selectedPayment);
            checkoutPayload['saveToken'] = (paymentOption_ID?.paymentOptionTypeDescription === 'Offline') ? false : savedThisCard?.checked;
            calculteDataUser["details"] = detailsArray?.length > 0 && detailsArray;
            calculteDataUser['priceType'] = _priceTypeForCalculate(comingFrom, autoshipProduct, autoshipOrders);
            calculteDataUser['Payments'] = [{ ...checkoutPayload, "maxAmount": +calculatedData?.oneTimeCalculateData?.orderTotal }];
            calculteDataUser['preAuthOrderId'] = +preAuthOrderId;
            calculteDataUser['orderId'] = +orderId;


            calculteDataUser['couponCodes'] = paymentOption_ID?.paymentOptionTypeDescription === 'Redirect' && couponCode?.code ? [couponCode.code.trim()] : [];

            // console.log("simplecheckoutPayload", simplecheckoutPayload, "preAuthOrderId", preAuthOrderId, "selectedPayment", selectedPayment);

            if (simplecheckoutPayload?.token || orderId || preAuthOrderId) {
                if (detailsArray?.length > 0) {
                    const POINT_DISCOUNT = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? [usePoint?.paymentObject] : [];
                    const POINT_AMOUNT = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? usePoint?.paymentObject?.maxAmount : 0;
                    calculteDataUser['Payments'] = [{
                        ...simplecheckoutPayload,
                        "maxAmount": +calculatedData?.oneTimeCalculateData?.orderTotal - POINT_AMOUNT,
                    }, ...POINT_DISCOUNT];

                    var date = new Date();
                    var TodaysDate = new Date();
                    date.setDate(date.getDate() + 365);
                    const REMAIN_ONETIME = _.filter(detailsArray, ({ itemId }) => (itemId !== MEMBERSHIP_AVAILABLE?.id))?.length > 0;
                    let VALUE = _.flatMap(_.map(autoshipOrders, (row) => row?.details));
                    // const FIND_OLD_PRODUCT = _.find(VALUE, (row) => row?.itemCode === "ADM00011US");
                    const FIND_PRODUCT = _.find(VALUE, (row) => row?.itemCode === "PVZ-IBO-FEEUS");
                    const memberProduct = _.find(autoshipOrders, row => row?.recurringOrderId === FIND_PRODUCT?.recurringOrderId)
                    // const memberOldProduct = _.find(autoshipOrders, row => row?.recurringOrderId === FIND_OLD_PRODUCT?.recurringOrderId)
                    const AUTOSHIP_PRODUCTS = FILTER_PRODUCTS?.find(row => row?.itemCode === 'PVZ-IBO-FEEUS') && memberProduct ? _productPayload([FIND_PRODUCT]) : (MEMBERSHIP_AVAILABLE) || (MEMBERSHIP_AVAILABLE && REMAIN_ONETIME) ? [...autoshipProduct, ..._productPayload([MEMBERSHIP_AVAILABLE])] : autoshipProduct;


                    dispatch(CreateOrderApi(calculteDataUser, AUTOSHIP_PRODUCTS, (orderid, autoShipToken, accountId) => {
                        if (!MEMBERSHIP_AVAILABLE) {
                            callBackAutoshipFunction(autoshipProduct, values, orderid, false, null, autoShipToken, accountId)
                        } else {
                            _membershipApiCall(orderid, { ...userDetails, ...Dates }, autoshipProduct, null, autoShipToken, accountId);
                        }
                    }, {
                        ...userDetails, ...Dates, membershipRenewalDate: memberProduct ? moment(userData?.membershipRenewalDate).add(1, 'y').utc().format() : (MEMBERSHIP_AVAILABLE) || (MEMBERSHIP_AVAILABLE && REMAIN_ONETIME) ?
                            FILTER_PRODUCTS?.find(row => row?.itemCode === 'PVZ-IBO-FEEUS') ? moment(userData?.membershipRenewalDate ? userData?.membershipRenewalDate : TodaysDate).add(1, 'y').utc().format() : moment(date).utc().format() : ""
                    }, comingFrom))

                } else {
                    callBackAutoshipFunction(autoshipProduct, values, null, false, null, autoShipToken, accountId)
                }
            } else {
                if (selectedPayment?.cardValue && selectedPayment?.selectedOptionForPayment === 1) {
                    checkoutPayload = {
                        ...checkoutPayload,
                        saveToken: false,
                        token: selectedPayment?.cardValue?.token,
                        customerAccountId: selectedPayment?.cardValue?.customerAccountId
                    }
                    // _onHandleSubmit(values, checkoutPayload, orderId, preAuthOrderId);
                }

                // else {
                // if (selectedPayment?.selectedOptionForPayment === 1) {
                //     setOnSelectedCardError('Please select your payment method.');
                //     _setTimeOut(() => setOnSelectedCardError(''), 3000);
                // }

                // else {

                const POINT_DISCOUNT = usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount ? [usePoint?.paymentObject] : [];
                const POINT_AMOUNT = usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount ? usePoint?.paymentObject?.maxAmount : 0;
                calculteDataUser = {
                    ...calculteDataUser,
                    Payments: [{
                        ...checkoutPayload,
                        token: selectedPayment?.cardValue?.token,
                        customerAccountId: selectedPayment?.cardValue?.customerAccountId,
                        "maxAmount": parseFloat(+calculatedData?.oneTimeCalculateData?.orderTotal - POINT_AMOUNT).toFixed(2),
                    }, ...POINT_DISCOUNT]
                }

                _scriptFunctionCall(paymentFormHtml, () => {
                    loopFunction(paymentFormHtml, ({ tokenId, orderId, preAuthOrderId }) => {

                        checkoutPayload["token"] = tokenId;
                        checkoutPayload['saveToken'] = (autoshipProduct?.length > 0) ? savedThisCard?.checked : savedThisCard?.checked ? savedThisCard?.checked : false;
                        _onHandleSubmit(values, checkoutPayload, orderId, preAuthOrderId);
                    },
                        ({ errorPayment }) => {
                            // setCheckoutProcessing(false);
                            setScriptError(errorPayment)
                            // if (actions) {
                            //     actions.setSubmitting(false);
                            // }
                        })

                }, calculteDataUser);

                // }
                // }
            }
        };

        return <Component {...props} {...{
            isUserLogin, error,
            formData, setFormData, selectedPayment, setSelectedPayment, savedCards, savedThisCard, setSavedThisCard, askPopupDlocal,
            usePoint, setUsePoint, onSelectedCardError, setOnSelectedCardError, otp, setOtp, otpPopUp, setOtpPopUp,
            countryState, setCountryState, shippingMethods, selectedShippingOption, calculatedData, paymentOptions, paymentFormHtml,
            userDetails, setUserDetails, checkValidUser, navigate, ROUTING_TEXT, isLoading, AllProductAndCheckoutText,
            useDefaultAddressOrNot, setUseDefaultAddressOrNot, userData, errorAddressData, couponCode, setCouponCode, couponErrorData,
            paymentOption_ID, iframeLoader, sameAsBillingChecked, setSameAsBillingChecked, shippingLoading,

            _handleShippingChange, handleChangeFunction: calculateNormalOrder, handleChangeValidate, _createMarchentProfile, _paymentOptionSetBilling,
            _productPayload, dispatch, _authenticateOtp, _onHandleSubmit, _handleCardChange, _onCouponClick, _handleDlocalPopup, _setUseDefaultAddressFunction, MEMBERSHIP_AVAILABLE, paymentError, scriptError
        }} />
    }

    return WithCheckoutHOComponent;
}

export default withCheckoutHOC;