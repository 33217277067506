import PropTypes from 'prop-types';

export const CountryAndStatePropsType = {
    countryCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    countryState: PropTypes.shape({
        country: PropTypes.string,
        countryError: PropTypes.string,
        state: PropTypes.string,
        stateError: PropTypes.string
    }).isRequired,
    setCountryState: PropTypes.func.isRequired,
    onChange: PropTypes.func
};

export const RegisterFormsPropsType = {
    buttonText: PropTypes.string,
    isUserCanLogin: PropTypes.bool,
    fieldValue: PropTypes.shape({
        errors: PropTypes.object,
        values: PropTypes.any,
        handleChange: PropTypes.func.isRequired,
        touched: PropTypes.object
    }),
    webAliesError: PropTypes.shape({
        isWebAliesError: PropTypes.string,
        isRefferalValid: PropTypes.bool,
        isRefferalError: PropTypes.string,
    }),
    ...CountryAndStatePropsType
}

export const ProductDisplayPropsType = {
    id: PropTypes?.number?.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    normal_oneTime: PropTypes.number?.isRequired,
    normal_autoship: PropTypes.number?.isRequired,
    retail_oneTime: PropTypes.number,
    retail_autoship: PropTypes.number,
    preffered_oneTime: PropTypes.number,
    preffered_autoship: PropTypes.number,
    quantity: PropTypes.number?.isRequired,
    isEligibleForAutoOrder: PropTypes.bool.isRequired,
    stock: PropTypes.string,
    out_of_stock:PropTypes.bool.isRequired,
}

export const OneTimePassPropsType = {
    otp: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleChangeValidate: PropTypes.func,
    _handleOtpSubmit: PropTypes.func,
    buttonText: PropTypes.string
}

export const ShoppingCartPropsType = {
    openMiniCart: PropTypes.bool.isRequired,
    setOpenMiniCart: PropTypes.func.isRequired,
    productData: PropTypes.arrayOf(PropTypes.object).isRequired,
    section: PropTypes.string.isRequired,
    _minCartQuantityIncDec: PropTypes.func.isRequired,
    _deleteProductFromCart: PropTypes.func.isRequired
}