import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SUPPORT_VALIDATION_OBJECT } from '../../services/Methods/validationSchema';
import { useDispatch } from 'react-redux';
import { ContactUsApi } from '../../services/Redux/Reducer/CustomerSlice';
import DialogComponent from '../../Inputs/DailogComponent';
import { SuccessDisplay } from '../../services/Methods/normalMethods';
import withRouter from '../../services/HOC/withRouterHOC';

const initialValues = { firstname: '', lastname: '', email: '', description: '', };

const customLabels = {
    firstname: ' First Name', lastname: 'Last Name', email: ' Email', description: 'Describe Your Issue',
};

const SupportContactUs = ({ showContact, setShowContact, MESSAGE_TEXT }) => {
    const dispatch = useDispatch()
    const [successMsg, setSuccessMsg] = useState({ open: false, msg: '' });

    return (
        <DialogComponent
            opend={showContact}
            handleClose={() => setShowContact(false)}
            title='Submit new ticket'
            classFor={"contact_usPopup"}
        >
            <p className='block text-[14] leading-[1.5] text-[#7d879c] mb-4'>{MESSAGE_TEXT?.supportTitle}</p>
            <Formik
                initialValues={initialValues}
                validationSchema={SUPPORT_VALIDATION_OBJECT}
                onSubmit={(values, action) => {
                    dispatch(ContactUsApi(values, (status) => {
                        setSuccessMsg({ open: true, msg: MESSAGE_TEXT?.successSupportMsg });
                        setTimeout(() => {
                            setSuccessMsg({ open: false, msg: "" });
                            setShowContact(false);
                        }, 6000);
                        action.resetForm(initialValues);
                    }))
                }}
            >
                <Form>
                    {Object.keys(initialValues).map((field) => (
                        <div key={field} className="mb-4 block">
                            <label htmlFor={field} className="text-[14px] leading-[1.5] text-[#373f50] block mb-[6px]">{customLabels[field]}</label>
                            {field === 'description' ? (
                                <Field className="block w-full py-[10px] px-4 border-[1px] border-[#dae1e7] rounded-[5px] text-[15px] text-[#4b566b] leading-[1.5] focus:outline-0 focus:border-[rgba(0,113,186,0.3)] h-[200px]" as="textarea" type="text" id={field} name={field} placeholder={customLabels[field]} />
                            ) : (
                                <Field className="block w-full py-[10px] px-4 border-[1px] border-[#dae1e7] rounded-[5px] text-[15px] text-[#4b566b] leading-[1.5] focus:outline-0 focus:border-[rgba(0,113,186,0.3)]" type="text" id={field} name={field} placeholder={customLabels[field]} />
                            )}
                            <ErrorMessage name={field} component="div" className="text-[#f34770] text-[14px] leading-[1.5] mt-1" />
                        </div>
                    ))}
                    <div className='flex flex-wrap justify-end mt-[36px] pt-[20px] border-t-[1px] border-[#e3e9ef]'>
                        <button className="block text-base px-[48px] py-[13px] rounded-[2px] bg-[#f3f5f9] text-[#2b3445] hover:bg-[#dae0ec] m-[4px] uppercase transition-all ease-out duration-300" onClick={() => setShowContact(false)}>Close</button>
                        <button className="block text-base px-[48px] py-[13px] rounded-[2px] bg-button-color text-[#fff] hover:bg-button-hover-color m-[4px] uppercase transition-all ease-out duration-300" type="submit">Submit ticket</button>
                    </div>

                    <DialogComponent opend={successMsg?.open} handleClose={() => setSuccessMsg({ open: false, msg: "" })} title="Success" classFor="">
                        <SuccessDisplay message={successMsg?.msg} />
                        <button className='successfull-msg' onClick={() => setSuccessMsg({ open: false, msg: "" })}>ok</button>
                    </DialogComponent>

                </Form>
            </Formik>
        </DialogComponent >
    )
};
export default withRouter(SupportContactUs);

